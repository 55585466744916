import styled from "styled-components";
import { theme } from "ui";

export const Select = styled.select`
  min-width: 100%;
  border-radius: 6px;
  padding: 0 12px;
  width: 100%;
  height: 4rem;
  border: ${`${theme.space.xpx} solid ${theme.colors.neutral["200"]}`};
`;

export const Option = styled.option`
  width: 100%;
`;
