import { useAuth, useToast } from "application";
import { AccountBaseModal } from "components";
import { cookie, weddingGiftListApi } from "implementations";
import { useState } from "react";
import { CreateGiftsDto, GiftVoucherDto } from "typing";
import { PlusInCircleIcon, Text, theme } from "ui";
import { AddVoucherBox } from "./AddVoucherBox";
import {
  AddProductCardWrapper,
  AddProductCard,
  Wrapper,
  GiftVoucherCardStyled,
} from "./styles";
import { useWeddingGiftListContext } from "../../../../context/state";

interface GiftVoucherCardProps {
  giftVoucherList: GiftVoucherDto[];
  isLoading?: boolean;
  addVoucherTrigger?: () => void;
}

interface updateBodyProps {
  product: string;
  quantity: number;
}

const GiftVoucherCard = ({
  giftVoucherList,
  isLoading,
  addVoucherTrigger,
}: GiftVoucherCardProps) => {
  const { getTokens } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAdding, setIsAdding] = useState(false);
  const [body, setBody] = useState<CreateGiftsDto[]>([]);
  const { addToast } = useToast();
  const { activeUser } = useWeddingGiftListContext();
  const addGiftVoucher = async () => {
    await weddingGiftListApi.createGift(
      body,
      getTokens()?.token as unknown as string
    );
    if (addVoucherTrigger) {
      addVoucherTrigger();
      addToast({
        title: "Item adicionado com sucesso",
        type: "success",
      });
    }
    setIsAdding(true);
  };

  const updateBody = ({ product, quantity }: updateBodyProps) => {
    const verify = body.find((item) => item.productId === product);
    const branchId =
      cookie.getCookie({
        name: "@FC:Ecom:Dropdown:BranchLocation",
      }) || "2";

    if (quantity > 0) {
      if (verify) {
        const updatedBody = body.map((obj) => {
          if (obj.productId === product) {
            return {
              ...obj,
              productQuantity: quantity,
              branchId: parseInt(branchId, 10),
            };
          }
          return obj;
        });
        setBody(updatedBody);
      } else {
        setBody([
          ...body,
          {
            listId: activeUser?.id,
            productId: product,
            packaging: 0,
            productQuantity: quantity,
            listEnviroment: -2,
            branchId: parseInt(branchId, 10),
          },
        ]);
      }
    } else {
      const newBody = body.filter((item) => item.productId !== product);
      setBody(newBody);
    }
  };

  const openModalEdit = () => {
    setIsModalOpen(true);
    setIsAdding(false);
  };

  return (
    <GiftVoucherCardStyled>
      <AccountBaseModal
        isOpen={isModalOpen}
        contentLabel="Adicionar Vale Presente"
        onCloseModal={() => {
          setIsModalOpen(false);
          setBody([]);
        }}
        width=""
        height="20rem"
        title="Adicionar Vale Presente"
        buttonText="Adicionar"
        AlternativeButtonText="Cancelar"
        buttonUnderAction={isAdding}
        buttonTextUnderAction="Adicionando..."
        buttonWidth="100px"
        modalButtonColor={theme.colors.secondary["265"]}
        onClickOnButton={() => {
          if (body?.length) {
            addGiftVoucher();
          }
          setIsModalOpen(false);
          setBody([]);
        }}
        onClickAlternativeButton={() => setIsModalOpen(false)}
        buttonHoverColor={theme.colors.secondary["700"]}
        footerGap="5.5rem"
        modalButtonPadding="2rem"
      >
        <Wrapper>
          {giftVoucherList?.map((item) => (
            <AddVoucherBox
              key={item.productId}
              giftVoucher={item}
              updateBody={(value: updateBodyProps) => updateBody(value)}
              isLoading={isLoading}
            />
          ))}
        </Wrapper>
      </AccountBaseModal>

      <AddProductCard cursor="pointer">
        <AddProductCardWrapper
          onClick={() => {
            openModalEdit();
          }}
        >
          <PlusInCircleIcon />
          <Text
            fontSize={`${theme.typography.fontSize.sm.x7}`}
            margin="1.35rem 0 0 0"
          >
            Adicione seu
          </Text>
          <Text
            fontSize={`${theme.typography.fontSize.sm.x7}`}
            margin="0"
            fontWeight={700}
          >
            Vale Presente
          </Text>
        </AddProductCardWrapper>
      </AddProductCard>
    </GiftVoucherCardStyled>
  );
};

export { GiftVoucherCard };
