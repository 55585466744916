import { weddingGiftListApi } from "implementations";
import { useAuth } from "application";
import PhotoAndCover from "./PhotoAndCover";
import SideBar from "./SideBar";
import { Container } from "./styles";
import { useWeddingGiftListContext } from "../context/state";

const Header = () => {
  const { getTokens } = useAuth();

  const { activeUser, searchListId } = useWeddingGiftListContext();

  const accessToken = getTokens()?.token as unknown as string;

  const listId = searchListId ? Number(searchListId) : activeUser?.id;

  const { data: infoHeader, isLoading } = weddingGiftListApi.fetchHeaderInfos(
    listId,
    !!listId
  );

  return (
    <Container>
      <PhotoAndCover
        {...{
          profileImage: infoHeader?.profileImage,
          backgroundImage: infoHeader?.backgroundImage,
          isUserList: Number(listId) === activeUser?.id,
        }}
      />
      <SideBar
        isUserList={Number(listId) === activeUser?.id}
        isLoading={isLoading}
        haveToken={!!accessToken}
        infoHeader={infoHeader}
        userInfo={{
          partner1: `${infoHeader?.primaryHost}`,
          partner2: `${infoHeader?.secondaryHost}`,
          listId,
        }}
      />
    </Container>
  );
};

export default Header;
