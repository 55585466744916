import { ImagesProduct } from "components";
import { EnvironmentsDto, GiftsListProduct } from "typing";
import { buildTestIds } from "application";
import { ModalProductDescription } from "../ModalProductDescription";
import {
  ModalBody,
  ProductDescriptionContainer,
  ProductImageContainer,
} from "./style";

interface ProductDataType {
  categoryId: number;
  family: number;
  fatembal: number;
  giftsQuantity: number;
  imageUrl: string;
  listId: number;
  measurementUnit: string;
  packaging: number;
  packagingQuantity: number;
  price: number;
  productBrand: string;
  productId: string;
  productLine: string;
  productName: string;
  quantity: number;
  salePrice: number;
  sellerId: number;
}
interface GiftListProductModalContentProps {
  productData?: GiftsListProduct;
  alterVoucherTrigger?: () => void;
  setIsModalOpen: (value: boolean) => void;
  oldQuantity: number;
  isMyList?: boolean;
  isMyPublishedList?: boolean;
  environment?: EnvironmentsDto | null | undefined;
}

const GiftListProductModalContent = ({
  productData,
  alterVoucherTrigger,
  setIsModalOpen,
  oldQuantity,
  isMyList,
  isMyPublishedList,
  environment,
}: GiftListProductModalContentProps) => {
  return (
    <ModalBody {...buildTestIds("modal-body")}>
      <ProductImageContainer {...buildTestIds("product-image-container")}>
        <ImagesProduct
          {...buildTestIds("images-product")}
          mediaLinks={[
            {
              productCode: "214",
              defaultImage: "",
              sequenceNumber: 5,
              description: " ",
              linkType: "THUMB",
              imageUrl: productData?.imageUrl as string,
              videoUrl: "",
              ranking: 5,
              dimension: "180",
              isDefault: true,
            },
            {
              productCode: "214",
              defaultImage: "",
              sequenceNumber: 5,
              description: " ",
              linkType: "IMAGEM",
              imageUrl: productData?.imageUrl as string,
              videoUrl: "",
              ranking: 5,
              dimension: "180",
              isDefault: true,
            },
          ]}
        />
      </ProductImageContainer>
      <ProductDescriptionContainer
        {...buildTestIds("product-description-container")}
      >
        <ModalProductDescription
          alterVoucherTrigger={alterVoucherTrigger}
          actionCloseModal={() => setIsModalOpen(false)}
          oldQuantity={oldQuantity}
          productData={productData as ProductDataType}
          environmentsList={environment}
          isMyList={isMyList}
          isMyPublishedList={isMyPublishedList}
        />
      </ProductDescriptionContainer>
    </ModalBody>
  );
};

export { GiftListProductModalContent };
