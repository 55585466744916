import styled from "styled-components";
import { Button, Container, Text, theme } from "ui";

export const InactiveWeddingListContainer = styled(Container)`
  display: flex;
  justify-content: center;
  max-width: 505px;
  padding-bottom: 1rem;

  @media ${theme.device.desktopFirst.tablet} {
    max-width: 300px;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    max-width: 250px;
  }
`;

export const LogoBottomLeftContent = styled.section`
  position: absolute;
  left: 0;
  top: 39rem;

  @media ${theme.device.desktopFirst.tabletXL} {
    top: 44.5rem;
  }

  @media ${theme.device.desktopFirst.tablet} {
    top: 48rem;
  }

  @media ${theme.device.desktopFirst.tabletS} {
    top: 47.5rem;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    top: 52.5rem;
  }

  @media (max-width: 428px) {
    top: 49.5rem;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    top: 49.6rem;
  }

  @media (max-width: 414px) {
    top: 50rem;
  }

  @media (max-width: 400px) {
    top: 49.5rem;
  }

  @media (max-width: 390px) {
    top: 49rem;
  }

  @media ${theme.device.desktopFirst.mobileS} {
    display: none;
  }
`;

export const InactiveWeddingListContent = styled.div`
  margin-bottom: 23.45px;
`;

export const LogoTopRightContent = styled.section`
  position: absolute;
  right: 0;
  margin-top: 0;

  @media ${theme.device.desktopFirst.mobileS} {
    display: none;
  }
`;

export const InactiveWeddingListWrapper = styled.div`
  margin: 3rem 0 9rem;

  @media ${theme.device.desktopFirst.tablet} {
    margin: 5rem 0;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    margin: 9rem 0 11rem;
  }

  @media ${theme.device.desktopFirst.mobileS} {
    margin: 2rem 0;
  }
`;

export const InactiveWeddingListTitle = styled.h1`
  color: ${theme.colors.primary["250"]};
  font-size: 2.08rem;
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 166.5%;
  margin-bottom: 1rem;

  @media ${theme.device.desktopFirst.tablet} {
    line-height: normal;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.md.x1};
  }
`;

export const InactiveWeddingListText = styled.h2`
  color: ${theme.colors.neutral["815"]};
  font-size: ${theme.typography.fontSize.md.x1};
  font-weight: ${theme.typography.fontWeight[400]};
  line-height: 166.5%;

  @media ${theme.device.desktopFirst.tablet} {
    line-height: normal;
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }
`;

export const InactiveWeddingListFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${theme.space.x4};
  margin-top: ${theme.space.x10};

  @media ${theme.device.desktopFirst.mobileXL} {
    margin-top: ${theme.space.x8};
  }
`;

export const InactiveWeddingListFooterTitle = styled.h4`
  color: ${theme.colors.neutral["815"]};
  font-size: ${theme.typography.fontSize.sm.x7};

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;

export const GoSearchText = styled(Text)`
  color: ${theme.colors.primary["200"]};
  font-size: ${theme.typography.fontSize.sm.x7};
  padding: 0px;
  margin: 0px;

  @media ${theme.device.desktopFirst.mobileXL} {
    font-size: ${theme.typography.fontSize.sm.x3};
  }
`;

export const FooterAction = styled(Button)`
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-start;
`;
