import { formatCurrency } from "application";
import { Skeleton } from "components";
import { useEffect, useState } from "react";
import { GiftVoucherDto } from "typing";
import { Text, theme } from "ui";
import {
  BottomBox,
  GiftVoucherContainer,
  GiftVoucherIconContainer,
  GiftVoucherImage,
} from "./styles";
import { QuantitySelection } from "../../components/QuantitySelection";

interface updateBodyProps {
  product: string;
  quantity: number;
}
interface AddVoucherBoxProps {
  giftVoucher?: GiftVoucherDto;
  updateBody: (value: updateBodyProps) => void;
  isLoading?: boolean;
}

const AddVoucherBox = ({
  giftVoucher,
  updateBody,
  isLoading,
}: AddVoucherBoxProps) => {
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    updateBody({ product: giftVoucher?.productId as string, quantity });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, giftVoucher]);

  return isLoading ? (
    <Skeleton
      type="card"
      gap="0.8rem"
      count={1}
      width="18rem"
      height="28rem"
      hasMargin={false}
    />
  ) : (
    <GiftVoucherContainer key={giftVoucher?.price}>
      <GiftVoucherIconContainer>
        <GiftVoucherImage
          src={
            (giftVoucher?.image as string) ||
            "https://fercos-s3-ecommerce.s3.amazonaws.com/listas/default/fotos/perfil/default"
          }
          alt="Foto do Cartão de Presente"
          width="190px"
          height="107px"
        />
      </GiftVoucherIconContainer>
      <Text fontSize="25px" fontWeight={700} margin="0">
        {formatCurrency(giftVoucher?.price as number)}
      </Text>
      <Text
        margin="0.5rem"
        fontSize="14px"
        color={`${theme.colors.neutral[450]}`}
      >
        Quantidade
      </Text>
      <BottomBox>
        <QuantitySelection setQuantity={setQuantity} quantity={quantity} />
        <div>{quantity === 1 ? "1 unidade" : `${quantity} unidades`}</div>
      </BottomBox>
    </GiftVoucherContainer>
  );
};

export { AddVoucherBox };
