import styled from "styled-components";
import { FlexContainer, theme } from "ui";

export const UserVoucherContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  @media ${theme.device.desktopFirst.mobileL} {
    justify-content: center;
    align-items: center;
  }
`;

export const TextContainer = styled(FlexContainer)`
  justify-content: start;
`;

export const CardBottom = styled(FlexContainer)`
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const UserGiftVoucherDivider = styled.div`
  background-color: ${theme.colors.neutral["450"]};
  width: 2px;
  height: 6px;
  box-shadow: 0 4px 8px 0 ${theme.colors.neutral["999-opacity-20"]},
    0 6px 20px 0 ${theme.colors.neutral["999-opacity-19"]};
`;

export const AlterButtonContainer = styled(FlexContainer)`
  font-size: ${theme.typography.fontSize.sm.x5};
  justify-content: center;
  width: 100%;
  color: ${theme.colors.primary["200"]};
  cursor: pointer;
  transition: color 300ms;

  &:hover {
    color: ${theme.colors.primary["300"]};
  }
`;

export const UserGiftVoucherCheckbox = styled(FlexContainer)`
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  border-radius: 3px;
  border: thin solid ${theme.colors.neutral["250"]};
  transition: border-color 300ms;

  &:hover {
    border-color: ${theme.colors.primary["200"]};
  }
`;

export const UserGiftVoucherCheckboxContainer = styled(FlexContainer)`
  width: 100%;
  justify-content: start;
`;

export const ProductImageContainer = styled(FlexContainer)`
  flex-direction: row;
  max-width: 35rem;
  padding: 1rem;
  max-height: 30rem;
  overflow: hidden;

  @media ${theme.device.desktopFirst.tablet} {
    width: 100%;
  }
`;

export const ProductDescriptionContainer = styled(FlexContainer)`
  flex-direction: row;
  max-width: 35rem;
  padding: 1rem;
`;

export const ModalBody = styled(FlexContainer)`
  flex-direction: row;
  width: 100%;

  @media ${theme.device.desktopFirst.tablet} {
    flex-direction: column;
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
