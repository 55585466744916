import Link from "next/link";
import { HeaderDto } from "typing";
import { Skeleton } from "components";
import { formatDatePhrase } from "application";
import { useRouter } from "next/router";
import {
  Container,
  Title,
  NameBrideAndGroom,
  Button,
  ButtonContainer,
  RedirectLink,
  DataAndLocationText,
  AlliancesIcon,
  TitleContainer,
} from "./styles";

interface SideBarProps {
  isUserList: boolean;
  infoHeader: HeaderDto | undefined | null;
  haveToken: boolean;
  isLoading: boolean;
  userInfo: {
    partner1?: string;
    partner2?: string;
    listId?: number;
  };
}

const SideBar = ({
  isUserList,
  infoHeader,
  haveToken,
  isLoading,
  userInfo,
}: SideBarProps) => {
  const onSkeleton = isLoading || !infoHeader || !userInfo;
  const router = useRouter();

  return (
    <Container>
      <TitleContainer>
        <AlliancesIcon />
        <Title>Lista de Casamento</Title>
      </TitleContainer>
      {onSkeleton ? (
        <Skeleton count={1} width="500px" height="45px" />
      ) : (
        <NameBrideAndGroom>
          {infoHeader?.primaryHost ?? userInfo?.partner1} &{" "}
          {infoHeader?.secondaryHost ?? userInfo?.partner2}
        </NameBrideAndGroom>
      )}
      {onSkeleton ? (
        <Skeleton count={1} width="500px" height="45px" />
      ) : (
        <ButtonContainer>
          {isUserList &&
          router.pathname === "/meu-painel/lista-de-presentes" ? (
            <Link href="/lista-de-casamento/meu-painel">
              <Button
                text="Gerenciar informações"
                textUnderAction="Gerenciar informações"
              />
            </Link>
          ) : (
            <DataAndLocationText>
              {formatDatePhrase(infoHeader?.eventDate)}{" "}
              <DataAndLocationText separator>em</DataAndLocationText>{" "}
              {infoHeader?.city}, {infoHeader?.state}{" "}
              {!haveToken ? (
                <RedirectLink href="/login?redirectTo=/lista-de-casamento/meu-painel">
                  É sua lista? Faça login
                </RedirectLink>
              ) : isUserList ? (
                <RedirectLink href="/lista-de-casamento/meu-painel/lista-de-presentes">
                  Gerenciar
                </RedirectLink>
              ) : (
                ""
              )}
            </DataAndLocationText>
          )}
        </ButtonContainer>
      )}
    </Container>
  );
};

export default SideBar;
