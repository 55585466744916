import { ArrowDown, ArrowUp } from "ui";
import { buildTestIds } from "application";
import {
  QuantitySelectionContainer,
  QuantitySelector,
  QuantityText,
} from "./styles";

interface QuantitySelectionProps {
  quantity: number;
  maxValue?: number;
  setQuantity: (value: number) => void;
}

const QuantitySelection = ({
  quantity,
  maxValue,
  setQuantity,
}: QuantitySelectionProps) => {
  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrease = () => {
    if (!maxValue || (maxValue && quantity < maxValue)) {
      setQuantity(quantity + 1);
    }
  };

  return (
    <QuantitySelectionContainer
      {...buildTestIds("quantity-selection-container")}
    >
      <QuantitySelector
        {...buildTestIds("quantity-selector-arrow-down")}
        onClick={handleDecrease}
      >
        <ArrowDown />
      </QuantitySelector>
      <QuantityText {...buildTestIds("quantity-text")}>{quantity}</QuantityText>
      <QuantitySelector
        {...buildTestIds("quantity-selector-arrow-up")}
        onClick={handleIncrease}
      >
        <ArrowUp />
      </QuantitySelector>
    </QuantitySelectionContainer>
  );
};

export { QuantitySelection };
