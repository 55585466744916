import { Image } from "components";
import styled from "styled-components";
import { FlexContainer, theme } from "ui";

export const GiftVoucherContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
  max-width: 18rem;
  border: thin solid ${theme.colors.neutral["150"]};
  min-height: 15rem;
  border-radius: 2px;
  padding: 1rem;
`;

export const BottomBox = styled(FlexContainer)`
  flex-direction: row;
  gap: 1rem;
  align-items: center;
`;

export const QuantitySelectionContainer = styled(FlexContainer)`
  flex-direction: row;
  gap: 0.5rem;
  width: 8rem;
  border-radius: 4px;
  background-color: ${theme.colors.neutral["100"]};
  justify-content: space-between;
  align-items: center;
`;

export const QuantitySelector = styled(FlexContainer)`
  width: 2rem;
  cursor: pointer;
  transition: 300ms ease;
  border-radius: 4px;
  &:hover {
    background-color: ${theme.colors.neutral["250"]};
  }
`;

export const GiftVoucherIconContainer = styled(FlexContainer)`
  padding: 3rem 0 4rem 0;
`;

export const GiftVoucherImage = styled(Image)`
  padding: 3rem 0 4rem 0;
`;

export const QuantityText = styled.div``;
