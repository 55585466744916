import styled from "styled-components";
import { theme } from "ui";
import { ButtonSubmit } from "components";
import { Alliances } from "ui/Icons";

interface SectionProps {
  separator?: boolean;
}

export const Container = styled.div`
  background-color: ${theme.colors.neutral.white};
  padding: 34px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid ${theme.colors.neutral["335"]};

  border-radius: 0 0 5px 5px;
`;

export const Title = styled.span`
  color: ${theme.colors.neutral["999"]};
  font-size: ${theme.typography.fontSize.sm.x7};
  margin-left: 0.5rem;

  @media (max-width: 755px) {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;

export const NameBrideAndGroom = styled.span`
  color: ${theme.colors.neutral["999"]};
  font-size: 40px;
  font-weight: ${theme.typography.fontWeight["700"]};
  font-family: ${theme.typography.fontFamily.playfairDisplay};
  padding: 20px 10px 16px;
  border-bottom: 1px solid ${theme.colors.neutral["99"]};
  display: block;

  @media (max-width: 755px) {
    font-size: ${theme.typography.fontSize.sm.x7};
  }
`;

export const Button = styled(ButtonSubmit).attrs(() => ({}))`
  padding-left: 50px;
  padding-right: 50px;
  transition: background-color 0.2s ease-in-out;
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
`;

export const DataAndLocationText = styled.span<SectionProps>`
  font-size: ${({ separator }) => (separator ? "1.2rem" : "1.4rem")};
  color: ${({ separator }) =>
    separator ? theme.colors.neutral["450"] : theme.colors.neutral["999"]};

  @media (max-width: 755px) {
    font-size: ${({ separator }) =>
      separator
        ? theme.typography.fontSize.sm.x5
        : theme.typography.fontSize.sm.x7};
  }
`;

export const RedirectLink = styled.a`
  font-size: 1.4rem;
  color: ${theme.colors.primary["200"]};

  @media (max-width: 755px) {
    font-size: ${theme.typography.fontSize.sm.x5};
  }
`;

export const AlliancesIcon = styled(Alliances).attrs(() => ({
  width: "28",
  height: "28",
  color: theme.colors.primary["200"],
}))``;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;
