import { Image } from "components";
import { ChangeEvent, useState } from "react";
import { useRouter } from "next/router";
import { weddingGiftListApi } from "implementations";
import { useAuth, useToast } from "application";
import { ImageTypeDto } from "typing";
import { WeddingRingsDefaultIcon } from "ui";
import {
  Container,
  Header,
  Photo,
  AlterImageContainer,
  AlterHeaderContainer,
  FileButton,
  EditIcon,
} from "./styles";
import ModalPhotos from "./ModalPhotos";
import { useWeddingGiftListContext } from "../../context/state";

type MarriageProps = {
  profileImage: string | null | undefined;
  backgroundImage: string | null | undefined;
  isUserList: boolean | null | undefined;
};

type HoverEditButtonStates = "0" | "1" | "2";

const Marriage = ({
  profileImage,
  backgroundImage,
  isUserList,
}: MarriageProps) => {
  const { getTokens } = useAuth();
  const { addToast } = useToast();
  const { activeUser } = useWeddingGiftListContext();
  const accessToken = getTokens()?.token;
  const router = useRouter();

  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [imageSrc, setImageSrc] = useState<string | undefined>("");
  const [selectedImageType, setSelectedImageType] = useState<
    ImageTypeDto | undefined
  >();
  const [isUpdatingImage, setIsUpdatingImage] = useState(false);
  const [lastUpdatedProfileImage, setLastUpdatedProfileImage] = useState<
    string | null
  >(null);
  const [lastUpdatedBackgroundImage, setLastUpdatedBackgroundImage] = useState<
    string | null
  >(null);
  const [isHoverEditButton, setIsHoverEditButton] =
    useState<HoverEditButtonStates>("0");
  const { id } = router.query;

  const isReducedScreen = window.innerWidth <= 755;

  const changePhoto = (
    event: ChangeEvent<HTMLInputElement>,
    imageType: ImageTypeDto
  ) => {
    const file = event?.target?.files;

    if (file) {
      const fileReader = new FileReader();

      fileReader.onload = () => {
        setImageSrc(fileReader.result as string);
      };

      fileReader.readAsDataURL(file[0]);

      setPhotoFile(file[0]);
      setSelectedImageType(imageType);
    }
  };

  const updateImage = async () => {
    setIsUpdatingImage(true);

    const response = await weddingGiftListApi.updateImage(
      id ? Number(id) : (activeUser?.id as unknown as number),
      selectedImageType as ImageTypeDto,
      photoFile as File,
      accessToken as string
    );

    setIsUpdatingImage(false);

    if ((response as { errors: unknown[] })?.errors?.length) {
      addToast({
        title: "Ops, ocorreu um erro ao alterar a imagem",
        type: "error",
      });
      return;
    }

    const formattedResponse = (response as { response: string })?.response;

    if (selectedImageType === "Background") {
      setLastUpdatedBackgroundImage(formattedResponse);
    }

    if (selectedImageType === "Profile") {
      setLastUpdatedProfileImage(formattedResponse);
    }

    setPhotoFile(null);

    addToast({
      title: "Imagem atualizada com sucesso!",
      type: "success",
    });
  };

  return (
    <Container>
      <ModalPhotos
        photoFile={photoFile}
        imageSrc={imageSrc}
        selectedImageType={selectedImageType}
        croppedImage={(file: File) => setPhotoFile(file)}
        closeModal={() => setPhotoFile(null)}
        updateImage={updateImage}
        isUpdatingImage={isUpdatingImage}
      />
      <Header isDisabled={isUpdatingImage}>
        <Image
          src={
            lastUpdatedBackgroundImage ||
            backgroundImage ||
            "https://fcx-s3-fercos-backoffice.ferreiracosta.com/listas_dev/default/fotos/capa/default"
          }
          alt={
            backgroundImage ? "Imagem de background" : "Alianças de Casamento"
          }
          layout="fill"
          objectFit="cover"
        />
        {isUserList ? (
          <AlterHeaderContainer
            onMouseEnter={() => setIsHoverEditButton("2")}
            onMouseLeave={() => setIsHoverEditButton("0")}
          >
            <FileButton
              key={photoFile?.name}
              onChange={(event) => {
                changePhoto(event, "Background");
              }}
              disabled={isUpdatingImage}
            />
            <EditIcon isHover={isHoverEditButton === "2"} />
          </AlterHeaderContainer>
        ) : null}
        <Photo>
          {lastUpdatedProfileImage || profileImage ? (
            <Image
              src={lastUpdatedProfileImage || imageSrc || profileImage || ""}
              alt="Casal de Noivos"
              layout="fill"
              objectFit="cover"
              style={{ borderRadius: "100%" }}
            />
          ) : (
            <WeddingRingsDefaultIcon />
          )}
          {isUserList ? (
            <AlterImageContainer
              isReducedScreen={isReducedScreen}
              isDisabled={isUpdatingImage}
              onMouseEnter={() => setIsHoverEditButton("1")}
              onMouseLeave={() => setIsHoverEditButton("0")}
            >
              <FileButton
                key={photoFile?.name}
                type="file"
                accept="image/png, image/jpeg"
                onChange={(event) => {
                  changePhoto(event, "Profile");
                }}
                disabled={isUpdatingImage}
              />
              <EditIcon isHover={isHoverEditButton === "1"} />
            </AlterImageContainer>
          ) : null}
        </Photo>
      </Header>
    </Container>
  );
};

export default Marriage;
