import { Checkbox, DropdownEllipsis, Register } from "components";
import { UseFormRegister } from "react-hook-form";
import { GiftsListProduct, IFormValues } from "typing";
import {
  EnvironmentHeaderContainer,
  EnvironmentHeaderTitle,
  EnvironmentHeaderCheckbox,
  EnvironmentHeaderActions,
} from "./styles";
import { useWeddingGiftListContext } from "../../../../context/state";

interface EnvironmentHeaderProps {
  id: number;
  name: string;
  products: GiftsListProduct[];
  selectAllProducts?: (isChecked?: boolean) => void;
  checkedAll?: boolean;
  isMyList: boolean;
}

const EnvironmentHeader = ({ isMyList, ...props }: EnvironmentHeaderProps) => {
  const { openModalEdit, openModalDelete } = useWeddingGiftListContext();

  const handleOpenModalRename = () => {
    openModalEdit(props);
  };

  const handleOpenModalDelete = () => {
    openModalDelete(props);
  };

  return (
    <EnvironmentHeaderContainer>
      {isMyList && (
        <EnvironmentHeaderCheckbox>
          <Checkbox
            id="environmentID"
            name="environmentID"
            checked={props.checkedAll}
            register={Register as unknown as UseFormRegister<IFormValues>}
            isCheckedEvent={props.selectAllProducts}
          />
        </EnvironmentHeaderCheckbox>
      )}

      <EnvironmentHeaderTitle>{props.name}</EnvironmentHeaderTitle>

      {props.name !== "Outros produtos" && isMyList && (
        <EnvironmentHeaderActions>
          <DropdownEllipsis
            data-testid="account-card-drop-down-ellipsis"
            content={{
              props: {
                marginTop: "2.35rem",
                marginLeft: "-8.35rem",
                width: "11.85rem",
              },
              options: [
                {
                  title: "Renomear ambiente",
                  onClickAction: handleOpenModalRename,
                },
                {
                  title: "Excluir ambiente",
                  onClickAction: handleOpenModalDelete,
                },
              ],
            }}
          />
        </EnvironmentHeaderActions>
      )}
    </EnvironmentHeaderContainer>
  );
};

export default EnvironmentHeader;
