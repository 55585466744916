import styled from "styled-components";
import { CloseIcon as Close, theme } from "ui";
import { Modal } from "components/Modal";

const BaseModal = styled(Modal)``;
interface BaseModalHeaderProps {
  justifyContent?: string;
}

const BaseModalHeader = styled.section<BaseModalHeaderProps>`
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: ${({ justifyContent }) => justifyContent ?? "space-between"};
  position: relative;
  z-index: 1;
  flex: 1;
`;

const BaseModalHeaderTitle = styled.h1`
  flex: 1;
  display: inline-flex;
  align-items: center;
  padding: 1rem 1rem 1rem 2.5rem;
  font-size: 2rem;
  font-weight: bold;
`;

const BaseModalHeaderClose = styled.section`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 6rem;
  cursor: pointer;
`;

const CloseIcon = styled(Close).attrs({
  width: "3.5rem",
  height: "3.5rem",
  strokeWidth: "0.75",
  color: theme.colors.primary["200"],
})``;

interface BaseModalBodyProps {
  backgroundColor?: string;
}

const BaseModalBody = styled.section<BaseModalBodyProps>`
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? theme.colors.neutral.white};
  /* padding: 2.5rem 2rem; */
  position: relative;
  overflow-y: auto;
  flex: 1;
`;

export {
  BaseModal,
  BaseModalHeader,
  BaseModalHeaderTitle,
  BaseModalHeaderClose,
  CloseIcon,
  BaseModalBody,
};
