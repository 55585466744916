import { useAppContext, useAuth, useToast } from "application";
import {
  AccountBaseModal,
  GiftsListFilterSidebar,
  RemoveSelection,
  Skeleton,
} from "components";
import InactiveWeddingList from "components/patterns/InactiveWeddingList";
import { weddingGiftListApi } from "implementations";
import { useCallback, useEffect, useState } from "react";
import {
  EnvironmentAddDto,
  EnvironmentDto,
  EnvironmentsDto,
  ErrorDto,
  GiftsListProduct,
  UpdateGiftDto,
} from "typing";
import {
  AddNewEnvironment,
  Container,
  Filter,
  FlexContainer,
  SpaceWrapper,
  Text,
  theme,
} from "ui";
import Header from "../Header";
import { useWeddingGiftListContext } from "../context/state";
import Environment from "./components/Environment";
import EnvironmentAddModal from "./components/EnvironmentAddModal";
import { GiftListProductModalContent } from "./components/GiftVoucherSection/UserGiftVoucher/components/GiftListProductModalContent";
import { ProductDetailModal } from "./components/GiftVoucherSection/UserGiftVoucher/components/ProductDetailModal";

import {
  EnvironmentAddButton,
  GiftsLisContent,
  GiftsListMain,
  IconWrapper,
  MobileFilterButton,
  SkeletonContainer,
  TextAlert,
} from "./styles";

export type FilterGiftsParams = {
  selectedFilterCategories: string[];
  selectedFilterBrands: string[];
  selectedFilterModels: string[];
};

type GiftsListProps = {
  weddingListId?: number;
  weddingListLink?: string;
  isListInactive?: boolean;
};

const GiftsList = ({ weddingListId, isListInactive }: GiftsListProps) => {
  const { getTokens } = useAuth();
  const accessToken = getTokens()?.token as string;
  const { addToast } = useToast();
  const { isClientTablet } = useAppContext();
  const [isFormInvalid, setIsFormInvalid] = useState(false);
  const [environmentToCreate, setEnvironment] = useState(
    undefined as unknown as EnvironmentAddDto
  );
  const [isUnderAction, setIsUnderAction] = useState(false);

  const [openMobileFilter, setOpenMobileFilter] = useState(false);

  const [selectedProducts, setSelectedProducts] = useState<GiftsListProduct[]>(
    []
  );

  const {
    modalConfig,
    environmentModalOpened,
    initialEnvironmentsList,
    environmentsFilteredList,
    isInitialLoadingEnvironments,
    isLoadingFilteredEnvironments,
    openModalAdd,
    openModalTransfer,
    openModalDeleteProducts,
    setEnvironmentModalOpened,
    isModalEdit,
    isModalDelete,
    isModalTransfer,
    isDeleteProducts,
    userLists,
    activeUser,
    triggerEnvironmentsFn,
    environmentToDelete,
    setParams,
    isProductDetailModalOpen,
    setIsProductDetailModalOpen,
    selectedProductDetail,
    setSelectedProductsList,
    triggerFilteredEnvironmentsFn,
    isMyList,
    isInvalid,
    setIsInvalid,
  } = useWeddingGiftListContext();

  const [environmentsList, setEnvironmentsList] = useState<
    EnvironmentsDto | null | undefined
  >(initialEnvironmentsList);

  const [isLoadingEnvironments, setIsLoadingEnvironments] = useState(
    isInitialLoadingEnvironments
  );

  useEffect(() => {
    setEnvironmentsList(initialEnvironmentsList);
  }, [initialEnvironmentsList]);

  useEffect(() => {
    setEnvironmentsList(environmentsFilteredList);
  }, [environmentsFilteredList]);

  useEffect(() => {
    setIsLoadingEnvironments(isLoadingFilteredEnvironments);
  }, [isLoadingFilteredEnvironments]);

  useEffect(() => {
    setSelectedProductsList(selectedProducts);
  }, [selectedProducts, setSelectedProductsList]);

  const handleOpenModalAdd = () => {
    setIsFormInvalid(true);
    openModalAdd();
  };

  const handleOpenModalDelete = () => {
    setIsFormInvalid(false);
    openModalDeleteProducts();
  };

  const handleSetIsFormInvalid = useCallback(
    (invalid: boolean) => {
      setIsFormInvalid(invalid);
    },
    [setIsFormInvalid]
  );

  useEffect(() => {
    handleSetIsFormInvalid(isInvalid);
  }, [isInvalid, handleSetIsFormInvalid]);

  const handleSetEnvironment = useCallback(
    (updateEnvironment: EnvironmentAddDto) => {
      setEnvironment(updateEnvironment);
    },
    [setEnvironment]
  );

  // Resolver assim que possível
  // eslint-disable-next-line sonarjs/cognitive-complexity
  const handleCreateEnvironment = async () => {
    setIsUnderAction(true);

    let responseStatus: number | null = null;
    let message = "";

    if (!isModalEdit && !isModalDelete && !isModalTransfer) {
      const environment: EnvironmentAddDto = {
        listId: activeUser?.id,
        ...environmentToCreate,
      };

      const response = (await weddingGiftListApi.createEnvironment(
        environment,
        accessToken
      )) as ErrorDto | undefined;

      if (response?.status === 400) {
        responseStatus = 400;
        message = "Não foi possível criar o ambiente";
      } else {
        message = "Ambiente adicionado com sucesso";
      }
    }

    if (isModalEdit) {
      const environment: EnvironmentAddDto = {
        ...environmentToCreate,
      };

      const response = (await weddingGiftListApi.renameEnvironment(
        environment,
        accessToken
      )) as ErrorDto | undefined;

      if (response?.status === 400) {
        responseStatus = 400;
        message = "Não foi possível renomear o ambiente";
      } else {
        message = "Ambiente renomeado com sucesso";
      }
    }

    if (isModalDelete && !isDeleteProducts) {
      const response = (await weddingGiftListApi.deleteEnvironment(
        environmentToDelete,
        accessToken
      )) as ErrorDto | undefined;

      if (!response) {
        message = "Ambiente removido com sucesso";
      } else {
        responseStatus = 400;
        message = "Não foi possível remover o ambiente";
      }
    }

    if (isModalTransfer || (isModalDelete && isDeleteProducts)) {
      let productsList;
      const productsToTransfer: UpdateGiftDto[] = [];

      if (isDeleteProducts) {
        selectedProducts?.forEach((product) => {
          productsToTransfer.push({
            productId: product.productId,
            quantity: 0,
          });
        });

        productsList = productsToTransfer;
        message = "Produto(s) removido(s) com sucesso";
      } else {
        productsList = environmentToCreate.productsList;
        message = "Produto(s) transferido(s) com sucesso";
      }

      const response = await weddingGiftListApi.updateGifts(
        activeUser?.id,
        productsList,
        accessToken
      );

      responseStatus = response?.status || 0;
      if (responseStatus === 400) {
        message = response?.errors?.Messages?.[0] || "";
      }
    }

    if (responseStatus !== 400) {
      triggerEnvironmentsFn();

      addToast({
        title: message,
        type: "success",
        timerInMilliseconds: 3000,
      });

      setSelectedProducts([]);
    } else {
      addToast({
        title: message,
        type: "error",
        timerInMilliseconds: 3000,
      });
    }

    setIsUnderAction(false);
    setIsFormInvalid(false);
    setEnvironmentModalOpened(false);
  };

  const handleProductsSelected = (product: GiftsListProduct) => {
    const hasItem = selectedProducts.find(
      (item) => item.productId === product.productId
    );
    if (hasItem) {
      const newBody = selectedProducts.filter(
        (item) => item.productId !== product.productId
      );
      setSelectedProducts(newBody);
    } else {
      setSelectedProducts([...selectedProducts, product]);
    }
  };

  const handleProductsChecked = (
    products: GiftsListProduct[],
    checked?: boolean
  ) => {
    if (checked) {
      const newBody = selectedProducts.filter((item) => {
        return products.every((product) => {
          return product.productId !== item.productId;
        });
      });
      setSelectedProducts([...newBody, ...products]);
    } else {
      const newBody = selectedProducts.filter(
        (item) => !products.includes(item)
      );
      setSelectedProducts(newBody);
    }
  };

  const [isMyPublishedList, setIsMyPublishedList] = useState(false);

  useEffect(() => {
    userLists?.map(({ id }) => {
      if (id === weddingListId) {
        setIsMyPublishedList(true);
      }

      return false;
    });
  }, [userLists, weddingListId]);

  if (isListInactive) {
    return <InactiveWeddingList />;
  }

  return (
    <>
      <Header />
      <Container>
        <FlexContainer>
          <GiftsLisContent padding={`${theme.space.x5} ${theme.space.x0}`}>
            <SpaceWrapper padding={`${theme.space.x0} ${theme.space.x4}`}>
              <GiftsListFilterSidebar
                facets={environmentsList?.facets}
                openMobile={openMobileFilter}
                handlerOpenFilterMobile={(openFilter) => {
                  setOpenMobileFilter(openFilter);
                }}
                isLoading={isLoadingEnvironments}
                onApplyFilters={(
                  activeCategoryFilters,
                  activeBrandFilters,
                  activeModelFilters
                ) => {
                  const updatedParams: FilterGiftsParams = {
                    selectedFilterCategories: activeCategoryFilters,
                    selectedFilterBrands: activeBrandFilters,
                    selectedFilterModels: activeModelFilters,
                  };

                  setParams(updatedParams);

                  if (updatedParams) {
                    triggerFilteredEnvironmentsFn();
                    setSelectedProducts([]);
                  }
                }}
                handlerClick={() => {
                  // window.scrollTo({ top: 200, left: 0, behavior: "smooth" });
                }}
              />
            </SpaceWrapper>
            <SpaceWrapper padding={`${theme.space.x0} ${theme.space.x4}`}>
              <GiftsListMain>
                {isClientTablet && (
                  <MobileFilterButton
                    onClick={() => setOpenMobileFilter(!openMobileFilter)}
                  >
                    <IconWrapper>
                      <Filter
                        width="18px"
                        color={`${theme.colors.neutral["520"]}`}
                      />
                    </IconWrapper>
                    Filtrar
                  </MobileFilterButton>
                )}
                <AccountBaseModal
                  isOpen={environmentModalOpened}
                  onCloseModal={() => {
                    setIsFormInvalid(true);
                    setIsInvalid(true);
                    setEnvironmentModalOpened(false);
                  }}
                  buttonType="submit"
                  buttonUnderAction={isUnderAction}
                  buttonWidth="200px"
                  isFormInvalid={isFormInvalid}
                  onClickOnButton={handleCreateEnvironment}
                  {...modalConfig}
                >
                  {!isModalDelete ? (
                    <EnvironmentAddModal
                      setIsFormInvalid={handleSetIsFormInvalid}
                      setEnvironment={handleSetEnvironment}
                      environmentsList={environmentsList?.environments}
                      productsList={selectedProducts}
                      isMyList={isMyList}
                    />
                  ) : (
                    <Text
                      fontSize="20px"
                      color={theme.colors.neutral["520"]}
                      textAlign="center"
                    >
                      Tem certeza que deseja remover o
                      {!isDeleteProducts
                        ? " ambiente e o(s) item(s) "
                        : " o(s) item(s) selecionados "}
                      da sua lista? <br />
                      <TextAlert> Esta ação não poderá ser desfeita</TextAlert>
                    </Text>
                  )}
                </AccountBaseModal>
                {isMyList && (
                  <EnvironmentAddButton onClick={handleOpenModalAdd}>
                    <IconWrapper>
                      <AddNewEnvironment />
                    </IconWrapper>
                    Criar novo ambiente
                  </EnvironmentAddButton>
                )}

                {!isLoadingEnvironments ? (
                  environmentsList?.environments?.map(
                    (environment: EnvironmentDto) => (
                      <Environment
                        key={environment.id}
                        environment={environment}
                        productsSelected={handleProductsSelected}
                        checkProducts={handleProductsChecked}
                        isMyList={isMyList}
                      />
                    )
                  )
                ) : (
                  <SkeletonContainer>
                    <Skeleton
                      type="card"
                      gap="0.8rem"
                      count={4}
                      width="18rem"
                      height="28rem"
                      hasMargin={false}
                    />
                  </SkeletonContainer>
                )}
              </GiftsListMain>
              {isMyList && (
                <RemoveSelection
                  selectedQuantity={selectedProducts.length}
                  onCancelSelection={() => {
                    setSelectedProducts([]);
                  }}
                  onRemoveSelection={handleOpenModalDelete}
                  onActionSelection={openModalTransfer}
                  actionButtonText="Escolher outro ambiente"
                  visible={!!selectedProducts.length}
                  selectedProducts={selectedProducts}
                  environments={environmentsList?.environments}
                />
              )}

              <ProductDetailModal
                isOpen={isProductDetailModalOpen}
                contentLabel="Alterar Vale Presente"
                onCloseModal={() => setIsProductDetailModalOpen(false)}
                height={isClientTablet ? "100vh" : "fit-content"}
              >
                <GiftListProductModalContent
                  environment={initialEnvironmentsList}
                  productData={selectedProductDetail}
                  alterVoucherTrigger={() => {
                    triggerEnvironmentsFn();
                    setSelectedProducts([]);
                  }}
                  setIsModalOpen={(value: boolean) =>
                    setIsProductDetailModalOpen(value)
                  }
                  oldQuantity={selectedProductDetail?.quantity ?? 0}
                  isMyList={isMyList}
                  isMyPublishedList={isMyPublishedList}
                />
              </ProductDetailModal>
            </SpaceWrapper>
          </GiftsLisContent>
        </FlexContainer>
      </Container>
    </>
  );
};

export default GiftsList;
