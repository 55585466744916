import styled from "styled-components";
import { FlexContainer, theme } from "ui";

export const ProductDescriptionContainer = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
`;

export const ProductDescriptionWrapper = styled(FlexContainer)`
  flex-direction: column;
  width: 100%;
`;

export const ProductDescription = styled(FlexContainer)`
  font-size: ${theme.typography.fontSize.md.x3};
  font-weight: ${theme.typography.fontWeight["700"]};
  color: ${theme.colors.neutral["999"]};
  padding-bottom: 1.3rem;
`;

export const ProductId = styled(FlexContainer)`
  font-size: ${theme.typography.fontSize.sm.x7};
  border-bottom: thin solid ${theme.colors.neutral["200"]};
  padding-bottom: 0.75rem;
  margin-bottom: 1.5rem;
  color: ${theme.colors.neutral["520"]};

  @media ${theme.device.desktopFirst.tablet} {
    margin-bottom: 0.75rem;
  }
`;

export const ProductQuantityText = styled.span`
  font-size: 1.2rem;
  margin-bottom: 5rem;
  color: ${theme.colors.neutral["520"]};
`;

export const ProductQuantityValue = styled.strong``;

export const ProductPrice = styled(FlexContainer)`
  font-size: ${theme.typography.fontSize.md.x4};
  color: ${theme.colors.primary["200"]};
  font-weight: ${theme.typography.fontWeight["700"]};
  padding-bottom: 2.5rem;

  @media ${theme.device.desktopFirst.tablet} {
    padding-bottom: 0.75rem;
  }
`;

export const Container = styled(FlexContainer)`
  width: 100%;
  padding-bottom: 2.5rem;
  align-items: center;
  gap: 1rem;
`;
export const GiftVoucherButtonContainer = styled(FlexContainer)`
  flex-direction: column;
  gap: 1rem;
`;

interface GiftVoucherButtonProps {
  color: string;
  backgroundColor: string;
  backgroundColorOnHover: string;
  border?: string;
  disabled?: boolean;
}

export const GiftVoucherButton = styled.button<GiftVoucherButtonProps>`
  display: flex;
  cursor: pointer;
  color: ${({ color }) => color};
  border: ${({ border }) => border ?? "none"};
  font-size: ${theme.typography.fontSize.sm.x7};
  height: 4rem;
  justify-content: center;
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 1rem;
  border-radius: 4px;
  transition: 300ms;
  margin-bottom: 1.3rem;
  gap: 1rem;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  padding: 0;

  &:hover {
    background-color: ${({ backgroundColorOnHover }) => backgroundColorOnHover};
  }
`;

export const UnitQuantityText = styled.div``;
