import { NextPage } from "next";
import { useAppContext } from "application";
import {
  Alliances,
  FurnitureGiftIcon,
  FurnitureGiftSmallIcon,
  HomeImprovementsIcon,
  HomeImprovementsSmallIcon,
  theme,
} from "ui";
import { useRouter } from "next/router";
import {
  FooterAction,
  GoSearchText,
  InactiveWeddingListContainer,
  InactiveWeddingListContent,
  InactiveWeddingListFooter,
  InactiveWeddingListFooterTitle,
  InactiveWeddingListText,
  InactiveWeddingListTitle,
  InactiveWeddingListWrapper,
  LogoBottomLeftContent,
  LogoTopRightContent,
} from "./styles";
import { GoBackIcon, GoHomeIcon, GoSearchIcon } from "../error404/styles";

const InactiveWeddingList: NextPage = () => {
  const { isClientTabletXL, handleFocusInputSearch } = useAppContext();
  const router = useRouter();

  return (
    <InactiveWeddingListContainer>
      <LogoBottomLeftContent>
        {!isClientTabletXL ? <FurnitureGiftIcon /> : <FurnitureGiftSmallIcon />}
      </LogoBottomLeftContent>
      <InactiveWeddingListWrapper>
        <InactiveWeddingListContent>
          <Alliances
            color={`${theme.colors.primary["150"]}`}
            width={isClientTabletXL ? 47 : 69}
            height={isClientTabletXL ? 44.65 : 65}
            strokeWidth="1"
          />
          <InactiveWeddingListTitle>
            Obrigado por acessar nossa lista de casamento!
          </InactiveWeddingListTitle>
          <InactiveWeddingListText>
            Lamentamos informar que a lista que você deseja acessar já concluiu
            o período de disponibilidade para compra de novos presentes.
          </InactiveWeddingListText>
        </InactiveWeddingListContent>
        <InactiveWeddingListFooter>
          <InactiveWeddingListFooterTitle>
            O que você pode fazer?
          </InactiveWeddingListFooterTitle>
          <FooterAction fill="clear" onClick={() => router.back()}>
            <GoBackIcon />
            <GoSearchText>Voltar para a página anterior</GoSearchText>
          </FooterAction>
          <FooterAction fill="clear" onClick={() => router.push("/")}>
            <GoHomeIcon />
            <GoSearchText>Ir para a página inicial</GoSearchText>
          </FooterAction>
          <FooterAction fill="clear" onClick={() => handleFocusInputSearch()}>
            <GoSearchIcon />
            <GoSearchText>Fazer uma nova busca</GoSearchText>
          </FooterAction>
        </InactiveWeddingListFooter>
      </InactiveWeddingListWrapper>
      <LogoTopRightContent>
        {isClientTabletXL ? (
          <HomeImprovementsSmallIcon />
        ) : (
          <HomeImprovementsIcon />
        )}
      </LogoTopRightContent>
    </InactiveWeddingListContainer>
  );
};

export default InactiveWeddingList;
