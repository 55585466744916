import {
  buildTestIds,
  formatCurrency,
  getClientBranchId,
  useAuth,
  useCartContext,
  useToast,
} from "application";
import {
  PopUpAlertConfirm,
  PopupAlertAddProductToWeddingList,
} from "components";
import { IconLoading } from "components/FormNewAddress/styles";
import {
  appMonitoringClient,
  cookie,
  weddingGiftListApi,
} from "implementations";
import { useRouter } from "next/router";
import { useState } from "react";
import {
  AddProductResponseDto,
  CoupleNamesDto,
  EnvironmentsDto,
  IHttpResponse,
} from "typing";
import { Bin, Cart, CheckDone, Gift, Text, theme } from "ui";
import { useWeddingGiftListContext } from "../../../../../../context/state";
import { QuantitySelection } from "../../../components/QuantitySelection";
import {
  Container,
  GiftVoucherButton,
  GiftVoucherButtonContainer,
  ProductDescription,
  ProductDescriptionContainer,
  ProductDescriptionWrapper,
  ProductId,
  ProductPrice,
  ProductQuantityText,
  ProductQuantityValue,
  UnitQuantityText,
} from "./style";

interface ProductDataType {
  categoryId: number;
  family: number;
  fatembal: number;
  giftsQuantity: number;
  imageUrl: string;
  listId: number;
  measurementUnit: string;
  packaging: number;
  packagingQuantity: number;
  price: number;
  productBrand: string;
  productId: string;
  productLine: string;
  productName: string;
  quantity: number;
  salePrice: number;
  sellerId: number;
}

interface ModalProductDescriptionProps {
  productData: ProductDataType;
  oldQuantity: number;
  actionCloseModal: () => void;
  alterVoucherTrigger?: () => void;
  isMyList?: boolean;
  environmentsList: EnvironmentsDto | null | undefined;
  isMyPublishedList?: boolean;
}

const ModalProductDescription = ({
  productData,
  oldQuantity,
  actionCloseModal,
  alterVoucherTrigger,
  isMyList,
  environmentsList,
  isMyPublishedList,
}: // henrique.melo reduzir complexidade desse componente
// eslint-disable-next-line sonarjs/cognitive-complexity
ModalProductDescriptionProps) => {
  const { getTokens } = useAuth();
  const { addToast } = useToast();
  const cart = useCartContext();
  const { activeUser, triggerEnvironmentsFn } = useWeddingGiftListContext();
  const router = useRouter();
  const [isWeddingListPopUpOpened, setIsWeddingListPopUpOpened] =
    useState(false);
  const [weddingListModalError, setWeddingListModalError] = useState(false);
  const [isSaveButtonUnderAction, setIsSaveButtonUnderAction] = useState(false);
  const [isLoadingButtonRemoveItem, setIsLoadingButtonRemoveItem] =
    useState(false);
  const branchId = getClientBranchId(cookie);

  const [quantity, setQuantity] = useState<number>(isMyList ? oldQuantity : 1);

  const remainingQuantity = oldQuantity;

  const sendProductToGiftCart = async () => {
    setIsSaveButtonUnderAction(true);
    cart
      .addProductToControlledCart({
        productId: productData?.productId,
        quantity,
        package: productData?.packaging,
        packingQuantity: productData?.packagingQuantity,
        /**
         * artur.castro essa mudança para o sellerId como 0, caso não tenha sellerdId, foi alinhado com Peixoto e Iroilton
         * na data (19/06/2023)
         */
        sellerId: productData?.sellerId || 0,
        listId: productData?.listId,
        cartId: cart.id,
        // price: productData?.price,
        // price: productData?.salePrice,
      })
      .then(() => {
        setIsSaveButtonUnderAction(false);
        actionCloseModal();
        router.push("/checkout/meu-carrinho");
      })
      .catch(() => {
        setIsSaveButtonUnderAction(false);
        addToast({
          title:
            "Ops, ocorreu um erro inesperado. Por favor, tente novamente em instantes.",
          type: "error",
        });
      });
  };

  const accessToken = getTokens()?.token;

  const { data: activeWeddingList } = weddingGiftListApi.getWeddingList(
    accessToken,
    undefined,
    Boolean(accessToken)
  ) as IHttpResponse<CoupleNamesDto, unknown>;

  const updateQuantity = async () => {
    return weddingGiftListApi.updateGifts(
      activeUser?.id as number,
      [
        {
          productId: productData?.productId,
          quantity,
        },
      ],
      accessToken as unknown as string
    );
  };

  const environmentFilter = (productId: string) => {
    const filteredList = environmentsList?.environments?.filter(
      (environment) => {
        const result = environment.products.find(
          (product) => product.productId === productId
        );

        return Boolean(result);
      }
    );

    if (filteredList?.length) {
      return -1;
    }

    return filteredList?.[0]?.id;
  };

  const addProductToWeddingList = async () => {
    try {
      setIsLoadingButtonRemoveItem(true);
      if (
        !productData ||
        quantity === 0 ||
        !activeUser ||
        !activeUser?.lastFiveNumbersGiftCard
      ) {
        return;
      }

      const response = (await weddingGiftListApi.addProductsToWeddingList(
        accessToken,
        [
          {
            packaging: productData?.packaging || 0,
            productId: productData?.productId || "",
            listId: activeUser?.id,
            listEnviroment: environmentFilter(productData.productId) as number,
            productQuantity: quantity,
            branchId: Number(branchId),
          },
        ]
      )) as unknown as AddProductResponseDto;

      if (response?.isValid) {
        setIsWeddingListPopUpOpened(true);
      } else {
        setWeddingListModalError(true);
      }
      setIsLoadingButtonRemoveItem(false);
    } catch (error) {
      appMonitoringClient.captureException(error);
    }
  };

  const removeItem = () => {
    setIsLoadingButtonRemoveItem(true);
    weddingGiftListApi.updateGifts(
      activeUser?.id as number,
      [
        {
          productId: productData?.productId,
          quantity: 0,
        },
      ],
      accessToken as unknown as string
    );
    setIsLoadingButtonRemoveItem(false);
  };

  const updateGiftQuantity = async () => {
    setIsSaveButtonUnderAction(true);

    const updateQuantityResponse = await updateQuantity();

    setIsSaveButtonUnderAction(false);

    if (updateQuantityResponse?.status === 400) {
      addToast({
        title:
          updateQuantityResponse?.errors?.Messages?.[0] ||
          "Ocorreu um erro ao salvar alterações",
        type: "error",
      });
      return;
    }

    actionCloseModal();

    if (alterVoucherTrigger) {
      alterVoucherTrigger();
    }

    addToast({
      title: "Item alterado com sucesso",
      type: "success",
    });
  };

  const handleRemoveItem = () => {
    removeItem();
    actionCloseModal();
    triggerEnvironmentsFn();
    if (alterVoucherTrigger) {
      alterVoucherTrigger();
      addToast({
        title: "Item removido com sucesso",
        type: "success",
      });
    }
  };

  return (
    <>
      <ProductDescriptionContainer
        {...buildTestIds("product-description-container")}
      >
        <ProductDescriptionWrapper
          {...buildTestIds("product-description-wrapper")}
        >
          <ProductDescription {...buildTestIds("product-description")}>
            {productData?.productName}
          </ProductDescription>
          <ProductId {...buildTestIds("product-id")}>
            {productData?.productId}
          </ProductId>
          {!isMyList ? (
            <ProductQuantityText {...buildTestIds("product-quantity-text")}>
              {remainingQuantity > 1 ? "Faltam" : "Falta"}{" "}
              <ProductQuantityValue {...buildTestIds("product-quantity-value")}>
                {remainingQuantity}{" "}
              </ProductQuantityValue>
              {remainingQuantity > 1 ? "unidades" : "unidade"}
            </ProductQuantityText>
          ) : null}
        </ProductDescriptionWrapper>
        <ProductPrice {...buildTestIds("product-price")}>
          {formatCurrency(productData?.salePrice)}
        </ProductPrice>
        {!isMyPublishedList ? (
          <>
            <Text
              {...buildTestIds("quantity-text")}
              fontSize="1.2rem"
              color={theme.colors.neutral["450"]}
            >
              {isMyList ? "Preciso de: " : "Quantidade"}
            </Text>
            <Container {...buildTestIds("quantity-items-container")}>
              <QuantitySelection
                quantity={quantity}
                maxValue={isMyList ? undefined : remainingQuantity}
                setQuantity={setQuantity}
              />
              <UnitQuantityText {...buildTestIds("unit-quantity-text")}>
                {quantity === 1 ? "1 unidade" : `${quantity} unidades`}
              </UnitQuantityText>
            </Container>
            <GiftVoucherButtonContainer
              {...buildTestIds("gift-someone-buttons-container")}
            >
              <GiftVoucherButton
                {...buildTestIds("gift-someone-first-button")}
                disabled={isSaveButtonUnderAction}
                color={`${theme.colors.neutral.white}`}
                backgroundColor={`${theme.colors.secondary["265"]}`}
                backgroundColorOnHover={`${theme.colors.secondary["315"]}`}
                onClick={isMyList ? updateGiftQuantity : sendProductToGiftCart}
              >
                {isSaveButtonUnderAction ? (
                  <IconLoading />
                ) : isMyList ? (
                  <CheckDone color="white" />
                ) : (
                  <Cart color="white" />
                )}
                {isMyList ? "Salvar alterações" : "Presentear"}
              </GiftVoucherButton>
              {isMyList || activeWeddingList?.id ? (
                <GiftVoucherButton
                  disabled={
                    isLoadingButtonRemoveItem || isSaveButtonUnderAction
                  }
                  color={`${theme.colors.primary["200"]}`}
                  backgroundColor={`${theme.colors.neutral.white}`}
                  backgroundColorOnHover={`${theme.colors.pink["50"]}`}
                  border={`1px solid ${theme.colors.primary["200"]}`}
                  onClick={
                    isMyList ? handleRemoveItem : addProductToWeddingList
                  }
                  {...buildTestIds("gift-someone-second-button")}
                >
                  {isLoadingButtonRemoveItem ? (
                    <IconLoading />
                  ) : isMyList ? (
                    <Bin color={`${theme.colors.primary["200"]}`} />
                  ) : (
                    <Gift color={`${theme.colors.primary["200"]}`} />
                  )}
                  {isMyList ? "Remover item" : "Adicionar à lista de presentes"}
                </GiftVoucherButton>
              ) : null}
            </GiftVoucherButtonContainer>
          </>
        ) : null}
      </ProductDescriptionContainer>
      <PopupAlertAddProductToWeddingList
        textDescriptionProduct={productData?.productName}
        titleAlert="ADICIONADO À LISTA"
        imageUrlProduct={productData?.imageUrl}
        isOpen={isWeddingListPopUpOpened}
        handlerStatusPopup={setIsWeddingListPopUpOpened}
      />
      <PopUpAlertConfirm
        textHeader="Falha ao adicionar produto"
        isOpen={weddingListModalError}
        actionCloseButton={() => setWeddingListModalError(false)}
      />
    </>
  );
};

export { ModalProductDescription };
