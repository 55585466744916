import styled from "styled-components";
import { ArrowLeftIcon, Button, HomeIcon, SearchIcon, Text, theme } from "ui";

export const Error404Container = styled.section`
  max-width: 930px;
  margin: 0 auto;
  padding: 3rem 0 1rem 0;

  @media ${theme.device.desktopFirst.tablet} {
    margin: 0 1rem;
  }

  @media ${theme.device.desktopFirst.mobileL} {
    padding: 1rem 0 1rem 0;
  }
`;

export const Error404Wrapper = styled.div`
  header {
    h1 {
      color: ${theme.colors.primary["200"]};
      font-size: 5.8rem;
      font-family: ${theme.typography.fontFamily.chivo};

      @media ${theme.device.desktopFirst.mobileL} {
        margin: 3.9rem 0;
      }
    }

    h3 {
      margin-top: 0.3rem;
      color: ${theme.colors.neutral["700"]};
      font-size: 2.5rem;
      font-weight: normal;
      margin-bottom: 1.5rem;
      font-family: ${theme.typography.fontFamily.chivo};

      @media ${theme.device.desktopFirst.mobileL} {
        font-size: 1.6rem;
        margin: 1.7rem 0;
      }
    }
  }

  footer {
    margin-top: 1rem;

    h4 {
      color: ${theme.colors.neutral["520"]};
      font-size: 2rem;
      font-weight: bold;
      margin-top: 1.6rem;

      @media ${theme.device.desktopFirst.mobileL} {
        font-size: 1.4rem;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      @media ${theme.device.desktopFirst.mobileL} {
        gap: 1rem;
      }

      span {
        font-size: 1.6rem;
        color: ${theme.colors.neutral["520"]};

        @media ${theme.device.desktopFirst.mobileL} {
          font-size: 1.3rem;
        }
      }

      a {
        display: flex;
        align-items: center;
        font-size: 1.375rem;

        @media ${theme.device.desktopFirst.mobileL} {
          font-size: 0.875rem;
        }

        svg {
          margin-right: 0.75rem;
        }
      }
    }

    div + div {
      margin-top: 1rem;
    }
  }
`;

export const ImageSadFaceContainer = styled.div`
  background: url("https://fcx-s3-fercos-backoffice.ferreiracosta.com/favicon/sadface.svg")
    no-repeat;
  background-size: 16.25rem;
  background-position: 100% 5%;

  @media ${theme.device.desktopFirst.mobileL} {
    background-position: 90% 5%;
    background-size: 95px;
    padding: 0 1rem;
  }
`;

export const GoSearchButton = styled(Button)`
  width: 100%;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-start;
`;

export const GoSearchText = styled(Text)`
  color: ${theme.colors.primary["200"]};
  font-size: 1.375rem;
  padding: 0px;
  margin: 0px;
`;

export const GoBackIcon = styled(ArrowLeftIcon).attrs({
  color: theme.colors.primary["200"],
})`
  font-size: 1.5rem;
  margin-right: 0.75rem;
`;

export const GoHomeIcon = styled(HomeIcon).attrs({
  color: theme.colors.primary["200"],
})`
  font-size: 1.5rem;
  margin-right: 0.75rem;
`;

export const GoSearchIcon = styled(SearchIcon).attrs({
  color: theme.colors.primary["200"],
})`
  font-size: 1.5rem;
  margin-right: 0.75rem;
`;
