import { useState } from "react";
import { EnvironmentDto } from "typing";
import { Option, Select } from "./styles";

interface EnvironmentSelectProps {
  data: EnvironmentDto[] | null | undefined;
  disabled?: boolean;
  onSelectOption: (value: string) => void;
}

const EnvironmentSelect = ({
  data: itemOption,
  disabled = false,
  onSelectOption,
}: EnvironmentSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<string>("");

  return (
    <Select
      id="select"
      name="select"
      disabled={disabled}
      onChange={(event) => {
        setSelectedOption(event.target.value);
        onSelectOption(event.target.value);
      }}
      value={selectedOption}
    >
      <Option value="">Selecione um ambiente</Option>
      {itemOption?.map((item) => {
        if (item.name !== "Cartões presente") {
          return (
            <Option key={item.id} value={item.id}>
              {item.name}
            </Option>
          );
        }
        return null;
      })}
    </Select>
  );
};

export { EnvironmentSelect };
