import styled from "styled-components";
import { theme } from "ui";

export const EnvironmentHeaderContainer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.neutral["99"]};
  margin-bottom: 20px;
  padding-bottom: 10px;
  justify-content: space-between;
`;

export const EnvironmentHeaderTitle = styled.h2`
  font-size: 24px;
  font-weight: normal;
  flex-grow: 1;
`;

export const EnvironmentHeaderCheckbox = styled.div`
  margin-right: 5px;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const EnvironmentHeaderActions = styled.div`
  align-self: right;
`;
