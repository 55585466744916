import { EnvironmentDto, GiftsListProduct } from "typing";
import { useCallback, useState } from "react";
import ProductsList from "../ProductsList";
import EnvironmentHeader from "./EnvironmentHeader";
import { EnvironmentComponent } from "./styles";

export interface EnvironmentProps {
  productsSelected: (productsSelected: GiftsListProduct) => void;
  checkProducts: (
    productsChecked: GiftsListProduct[],
    checked?: boolean
  ) => void;
  environment: EnvironmentDto;
  isMyList: boolean;
}

const Environment = ({
  productsSelected,
  environment,
  isMyList,
  checkProducts,
}: EnvironmentProps) => {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [checkAllProducts, setCheckAllProducts] = useState<boolean | undefined>(
    false
  );
  const [isCheckedAll, setCheckedAll] = useState<boolean>(false);

  const handleProductSelected = useCallback(
    (product: GiftsListProduct) => {
      if (product.isSelected) {
        selectedProducts.push(product.productId);
      } else {
        const index = selectedProducts.indexOf(product.productId);
        if (selectedProducts.indexOf(product.productId) !== -1) {
          selectedProducts.splice(index, 1);
        }
      }

      setCheckedAll(false);

      setSelectedProducts(selectedProducts);
      productsSelected(product);
    },
    [productsSelected, selectedProducts, setCheckedAll]
  );

  return (
    <EnvironmentComponent>
      {isMyList || (!isMyList && !!environment?.products?.length) ? (
        <EnvironmentHeader
          isMyList={isMyList}
          checkedAll={isCheckedAll}
          {...environment}
          selectAllProducts={(checked?: boolean) => {
            setCheckedAll(true);
            setCheckAllProducts(checked);
            checkProducts(environment?.products, checked);
          }}
        />
      ) : null}

      <ProductsList
        environment={environment}
        products={environment?.products}
        productsSelected={handleProductSelected}
        isMyList={isMyList}
        checkProducts={checkAllProducts}
      />
    </EnvironmentComponent>
  );
};

export default Environment;
