import styled, { css } from "styled-components";
import { DefaultProductImage, FlexContainer, Heart, Title, theme } from "ui";

type ProductCardProps = {
  isCompact?: boolean;
  isSelected: boolean;
  isDisabled?: boolean;
};

export const ProductCardStyled = styled(FlexContainer)<ProductCardProps>`
  flex-direction: column;
  align-items: left;
  cursor: pointer;
  height: ${({ isCompact }) => (!isCompact ? "350px" : "150px")};
  min-height: ${({ isCompact }) => (!isCompact ? "28rem" : "auto")};
  background-color: ${theme.colors.neutral.white};
  border: ${({ isSelected }) =>
    !isSelected
      ? `thin solid ${theme.colors.neutral["100"]}`
      : `thin solid ${theme.colors.secondary["200"]}`};
  border-radius: 2px;
  user-select: none;
  transition: all 0.2s linear;
  position: relative;
  -webkit-tap-highlight-color: transparent !important;
  max-width: ${({ isCompact }) => (!isCompact ? "18rem;" : "150px")};
  padding: ${theme.space["x3.5"]};

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  @media ${theme.device.desktopFirst.mobileXL} {
    height: ${({ isCompact }) => (!isCompact ? "325px" : "125px")};
    max-width: calc(50% - 10px);
  }
`;

export const GiftDefaultProductImage = styled(DefaultProductImage).attrs({
  width: 100,
  height: 100,
})``;

export const ProductCardImage = styled.figure`
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  text-align: center;
  transition: all 0.2s linear;
  padding-top: 4px;
  height: 150px;

  @media ${theme.device.desktopFirst.mobileL} {
    height: 154px !important;
    padding-top: 0px;
  }
`;

export const ProductCardContent = styled.div`
  display: inline-block;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

export const ProductCardTitle = styled(Title)`
  font-size: ${theme.typography.fontSize.sm.x7};
  min-height: 58px;
  max-height: 58px;
  margin-bottom: 6px;
  overflow: hidden;
  line-height: 19px;
  font-weight: ${theme.typography.fontWeight.normal};
  color: ${theme.colors.neutral["520"]};

  @media ${theme.device.desktopFirst.mobileL} {
    font-size: ${theme.typography.fontSize.sm.x5};
    min-height: 54px;
    max-height: 54px;
    margin-bottom: 4px;
    overflow: hidden;
    line-height: 18px;
  }
`;

export const ProductCardCurrentPrice = styled(FlexContainer)`
  align-items: center;
  justify-content: flex-start;
  font-size: ${theme.typography.fontSize.md.x3};
  font-weight: ${theme.typography.fontWeight.bold};
  line-height: 28px;
  letter-spacing: -0.69px;
  margin: 1rem 0;

  @media ${theme.device.desktopFirst.mobileL} {
    font-size: ${theme.typography.fontSize.sm.x9};
    line-height: 18px;
  }
`;

export const ProductCardInfo = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
  padding: ${theme.space["x0.5"]} 0 ${theme.space["x2.5"]} 0;
`;

export const ProductRequested = styled.span`
  font-size: ${theme.typography.fontSize.sm.x3};
  color: ${theme.colors.neutral["450"]};
`;

export const ProductPurchased = styled.span`
  font-size: ${theme.typography.fontSize.sm.x3};
  color: ${theme.colors.neutral["450"]};
`;

export const ProductCardButton = styled.span`
  font-size: ${theme.typography.fontSize.sm.x5};
  color: ${theme.colors.primary["200"]};
  text-align: center;
`;

export const ProductHaveBeenPurchasedContent = styled.section`
  border-radius: 4px;
  border: 1px solid ${theme.colors.neutral["100"]};
  background: ${theme.colors.neutral.white};
  padding: 0.5rem 1rem;
  height: 4.5rem;
  width: 100%;
  max-width: 186px;

  position: relative;
  top: 30%;

  @media ${theme.device.desktopFirst.mobileS} {
    padding: 0.5rem;
  }
`;

export const ProductHaveBeenPurchasedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 100%;
  width: 100%;
`;

export const HeartIcon = styled(Heart).attrs({
  width: 32,
  height: 32,
})``;

export const ProductHaveBeenPurchasedText = styled.span`
  font-size: 1.125rem;
  text-align: left;
  color: ${theme.colors.secondary["400"]};
  font-weight: ${theme.typography.fontWeight["600"]};
  font-family: ${theme.typography.fontFamily.chivo}, sans-serif;

  @media ${theme.device.desktopFirst.mobileXL} {
    text-align: center;
    font-size: 0.975rem;
  }

  @media ${theme.device.desktopFirst.mobileS} {
    font-size: 0.875rem;
  }
`;
