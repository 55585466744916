import { CropImage } from "components/CropImage";
import { ImageTypeDto } from "typing";
import {
  Container,
  InfoContainer,
  InfoText,
  TitleText,
  CropContainer,
  HeaderContainer,
  CloseIcon,
  CloseContainer,
  ButtonsContainer,
  Button,
} from "./styles";

interface ModalPhotosProps {
  photoFile: File | null;
  croppedImage: (file: File) => void;
  imageSrc: string | undefined;
  closeModal: () => void;
  updateImage: () => void;
  isUpdatingImage: boolean;
  selectedImageType?: ImageTypeDto;
}

const ModalPhotos = ({
  croppedImage,
  imageSrc,
  closeModal,
  updateImage,
  isUpdatingImage,
  photoFile,
  selectedImageType,
}: ModalPhotosProps) => {
  return (
    <Container
      isOpen={!!photoFile}
      onCloseModal={closeModal}
      contentLabel="Photo crop"
    >
      <HeaderContainer>
        <InfoContainer>
          <TitleText>Arraste para reposicionar a foto</TitleText>
          <InfoText>
            Você pode dar zoom e/ou mover a imagem para personalizar como
            preferir
          </InfoText>
        </InfoContainer>
        <CloseContainer onClick={closeModal} isDisabled={isUpdatingImage}>
          <CloseIcon />
        </CloseContainer>
      </HeaderContainer>
      <CropContainer>
        <CropImage
          imageInfo={{
            imageSrc,
            name: photoFile?.name,
            type: photoFile?.type,
            formatPhoto: selectedImageType,
          }}
          croppedImage={croppedImage}
        />
      </CropContainer>
      <ButtonsContainer>
        <Button
          text="Cancelar"
          onClickAction={closeModal}
          textUnderAction="Cancelar"
          isDisabled={isUpdatingImage}
        />
        <Button
          text="Adicionar"
          submitButton
          onClickAction={() => updateImage()}
          textUnderAction="Adicionando..."
          underAction={isUpdatingImage}
        />
      </ButtonsContainer>
    </Container>
  );
};

export default ModalPhotos;
