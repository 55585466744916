import styled from "styled-components";
import { Modal } from "components/Modal";
import { theme, CloseIcon as Close } from "ui";
import { ButtonSubmit } from "components/ButtonSubmit";

interface SectionProps {
  submitButton?: boolean;
}

export const Container = styled(Modal).attrs(() => ({
  styles: {
    content: {
      width: "425px",
      padding: 0,
    },
  },
}))``;

export const HeaderContainer = styled.div`
  display: flex;
  padding: 28px 24px 20px;
  align-items: center;
  font-family: ${theme.typography.fontFamily.chivo};
`;

type CloseContainerProps = {
  isDisabled: boolean;
};

export const CloseContainer = styled.section<CloseContainerProps>`
  pointer-events: ${({ isDisabled }) => (isDisabled ? "none" : "auto")};
  opacity: ${({ isDisabled }) => (isDisabled ? "0.5" : "1")};

  :hover {
    cursor: ${({ isDisabled }) => (isDisabled ? "auto" : "pointer")};
  }
`;

export const CloseIcon = styled(Close).attrs(() => ({
  width: 32,
  height: 32,
  color: theme.colors.primary["200"],
}))``;

export const InfoContainer = styled.div``;

export const TitleText = styled.h2`
  color: ${theme.colors.neutral["999"]};
  font-weight: ${theme.typography.fontWeight["500"]};
  font-size: ${theme.typography.fontSize.md.x3};
  margin-bottom: 14px;
`;

export const InfoText = styled.p`
  color: ${theme.colors.neutral["999"]};
  font-size: ${theme.typography.fontSize.sm.x7};
`;

export const CropContainer = styled.div`
  position: relative;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  padding: 28px 24px 20px;
  justify-content: space-evenly;
`;

export const Button = styled(ButtonSubmit).attrs(
  ({ submitButton }: SectionProps) => ({
    hoveredColor: submitButton ? theme.colors.secondary["265"] : "transparent",
    color: submitButton ? theme.colors.secondary["265"] : "transparent",
    contentColor: submitButton
      ? theme.colors.neutral.white
      : theme.colors.secondary["265"],
  })
)<SectionProps>`
  font-size: 20px;
  font-weight: ${theme.typography.fontWeight["600"]};
  padding: 0 2.4rem;
  border: ${({ submitButton }: SectionProps) =>
    submitButton
      ? "none"
      : `2px solid
    ${theme.colors.secondary["265"]}`};

  @media (max-width: 755px) {
    font-size: 16px;
    height: 3.4rem;
  }
`;
