import { Checkbox, Register } from "components";
import { Image } from "components/Image";
import { useEffect, useState } from "react";
import { UseFormRegister } from "react-hook-form";
import { GiftsListProduct, IFormValues } from "typing";
import { buildTestIds, useAppContext } from "application";
import { UserGiftVoucherDivider } from "../GiftVoucherSection/UserGiftVoucher/styles";
import {
  GiftDefaultProductImage,
  HeartIcon,
  ProductCardButton,
  ProductCardContent,
  ProductCardCurrentPrice,
  ProductCardImage,
  ProductCardInfo,
  ProductCardStyled,
  ProductCardTitle,
  ProductHaveBeenPurchasedContent,
  ProductHaveBeenPurchasedText,
  ProductHaveBeenPurchasedWrapper,
  ProductPurchased,
  ProductRequested,
} from "./styles";
import { useWeddingGiftListContext } from "../../../context/state";

export interface ProductCardProps {
  item: GiftsListProduct;
  compact?: boolean;
  isSelected: (product: GiftsListProduct) => void;
  checkProduct?: boolean;
  isMyList: boolean;
}

const ProductCard = ({
  item,
  compact,
  isSelected,
  isMyList,
  checkProduct,
}: ProductCardProps) => {
  const [isProductSelected, setIsSelected] = useState(false);
  const { salePrice: itemPrice } = item;
  const {
    setIsProductDetailModalOpen,
    setSelectedProductDetail,
    selectedProductsList,
  } = useWeddingGiftListContext();

  const { isClientMobile } = useAppContext();

  const itemPriceFormatted = itemPrice.toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  const allGiftListProductsHaveBeenPurchased =
    !item?.availableQuantity || item?.availableQuantity <= 0;

  useEffect(() => {
    if (
      selectedProductsList?.filter(
        (value: GiftsListProduct) => value.productId === item.productId
      )?.length > 0
    ) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [item, selectedProductsList, setIsSelected]);

  const handleSelectProduct = (checked?: boolean) => {
    setIsSelected(checked || !isProductSelected);

    isSelected({
      ...item,
      isSelected: checked || !isProductSelected,
    });
  };

  useEffect(() => {
    setIsSelected(!!checkProduct);
  }, [checkProduct]);

  if (isMyList && allGiftListProductsHaveBeenPurchased) {
    return null;
  }

  const giftListImageUrl = item?.imageUrl;

  return (
    <ProductCardStyled
      {...buildTestIds("product-card-styled")}
      isCompact={compact}
      isSelected={isProductSelected}
      isDisabled={allGiftListProductsHaveBeenPurchased}
      onClick={() => {
        if (!isMyList) {
          setIsProductDetailModalOpen(true);
          setSelectedProductDetail(item);
        } else if (compact) {
          handleSelectProduct();
        }
      }}
    >
      {!compact && isMyList && (
        <Checkbox
          id="environmentID"
          name="environmentID"
          register={Register as unknown as UseFormRegister<IFormValues>}
          checked={isProductSelected}
          isCheckedEvent={handleSelectProduct}
        />
      )}
      <ProductCardImage>
        {giftListImageUrl ? (
          <Image
            src={giftListImageUrl}
            alt={item?.productBrand || "Imagem do produto"}
            width={170}
            height={120}
            objectFit="contain"
          />
        ) : (
          <GiftDefaultProductImage />
        )}
      </ProductCardImage>
      {!compact && (
        <>
          <ProductCardContent>
            <ProductCardTitle>{item?.productName}</ProductCardTitle>

            {allGiftListProductsHaveBeenPurchased ? (
              <ProductHaveBeenPurchasedContent>
                <ProductHaveBeenPurchasedWrapper>
                  {isClientMobile ? null : <HeartIcon />}
                  <ProductHaveBeenPurchasedText>
                    Esse presente já foi comprado
                  </ProductHaveBeenPurchasedText>
                </ProductHaveBeenPurchasedWrapper>
              </ProductHaveBeenPurchasedContent>
            ) : (
              <ProductCardCurrentPrice>
                {itemPriceFormatted}
              </ProductCardCurrentPrice>
            )}
          </ProductCardContent>
          {isMyList && (
            <>
              <ProductCardInfo>
                <ProductRequested>{`${item?.quantity} ${
                  item?.quantity <= 1 ? "Solicitado" : "Solicitados"
                } `}</ProductRequested>
                <UserGiftVoucherDivider />
                <ProductPurchased>{`${item?.giftsQuantity} ${
                  item?.giftsQuantity <= 1 ? "Comprado" : "Comprados"
                } `}</ProductPurchased>
              </ProductCardInfo>
              <ProductCardButton
                onClick={() => {
                  setIsProductDetailModalOpen(true);
                  setSelectedProductDetail(item);
                }}
              >
                alterar
              </ProductCardButton>
            </>
          )}
        </>
      )}
    </ProductCardStyled>
  );
};

export default ProductCard;
