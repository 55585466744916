import { useState } from "react";
import { EnvironmentDto, GiftVoucherDto, GiftsListProduct } from "typing";
// import { PlusInCircleIcon, Text, theme } from "ui";
import { weddingGiftListApi } from "implementations";
import { buildTestIds, useAuth } from "application";
import ProductCard from "../Product";
import { GiftVoucherCard } from "../GiftVoucherSection/GiftVoucherCard";
import { useWeddingGiftListContext } from "../../../context/state";
import {
  // AddProductCardWrapper,
  // AddProductCard,
  ProductsListContainer,
} from "./styles";

export interface ProductListProps {
  products: GiftsListProduct[];
  compact?: boolean;
  productsSelected: (productsSelected: GiftsListProduct) => void;
  environment?: EnvironmentDto;
  isMyList: boolean;
  checkProducts?: boolean;
}

const ProductsList = ({
  products,
  compact,
  productsSelected,
  environment,
  isMyList,
  checkProducts,
}: ProductListProps) => {
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

  const { triggerEnvironmentsFn } = useWeddingGiftListContext();

  const { getTokens, isAuthenticated } = useAuth();

  const accessToken = getTokens()?.token ?? "";

  const { data: giftVoucherList, isLoading: isLoadingGifts } =
    weddingGiftListApi.getGiftsVoucher(accessToken, isAuthenticated);

  const handleProductSelected = (product: GiftsListProduct) => {
    if (product.isSelected) {
      selectedProducts.push(product.productId);
    } else {
      const index = selectedProducts.indexOf(product.productId);
      if (selectedProducts.indexOf(product.productId) !== -1) {
        selectedProducts.splice(index, 1);
      }
    }

    setSelectedProducts(selectedProducts);
    productsSelected(product);
  };

  return (
    <ProductsListContainer
      {...buildTestIds("products-list-container")}
      itensQuantity={products?.length || 0}
    >
      {environment?.id === -2 && isMyList && (
        <GiftVoucherCard
          {...buildTestIds("gift-voucher-card")}
          giftVoucherList={giftVoucherList as GiftVoucherDto[]}
          isLoading={isLoadingGifts}
          addVoucherTrigger={triggerEnvironmentsFn}
        />
      )}
      {/* Esse compoennt deve ser descomentando assim que o recurso de adicoinar presente através da landing page seja desenvolvido */}
      {/* // : (
      //   !compact &&
      //   isMyList && (
      //     <AddProductCard cursor="pointer">
      //       <AddProductCardWrapper onClick={() => false}>
      //         <PlusInCircleIcon />
      //         <Text
      //           fontSize={`${theme.typography.fontSize.sm.x7}`}
      //           margin="1.35rem 0 0 0"
      //         >
      //           Adicione itens em
      //         </Text>
      //         <Text
      //           fontSize={`${theme.typography.fontSize.sm.x7}`}
      //           margin="0"
      //           fontWeight={700}
      //           textAlign="center"
      //         >
      //           {environment?.name}
      //         </Text>
      //       </AddProductCardWrapper>
      //     </AddProductCard>
      //   )
      // )} */}

      {products?.length
        ? products?.map((product: GiftsListProduct) => (
            <ProductCard
              key={`${product?.productId}#${product?.giftsQuantity}`}
              compact={compact}
              item={product}
              isSelected={handleProductSelected}
              checkProduct={checkProducts}
              isMyList={isMyList}
            />
          ))
        : null}
    </ProductsListContainer>
  );
};

export default ProductsList;
