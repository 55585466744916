import styled from "styled-components";
import { FlexContainer, theme } from "ui";

export const EnvironmentAddModalStyled = styled(FlexContainer)`
  flex-direction: column;
`;

export const EnvironmentAddProductsList = styled(FlexContainer)`
  margin-bottom: 10px;
  flex-direction: column;
  overflow: hidden;
  overflow-y: scroll;
  height: 300px;
  padding: ${theme.space.x5};
  border: 1px solid ${theme.colors.neutral["200"]};
`;

export const Environment = styled(FlexContainer)`
  margin-bottom: 30px;
  flex-direction: column;
`;

export const EnvironmentName = styled.div``;

export const EnvironmentProductsListName = styled(FlexContainer)`
  margin-bottom: 20px;
`;

export const EnvironmentProductsListTitle = styled.div`
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const EnvironmentProductsListFooter = styled.div``;

export const EnvironmentSelectStyled = styled.div``;
export const EnvironmentSelectedProducts = styled(FlexContainer)``;
export const SelectedProduct = styled.div``;
