import styled from "styled-components";
import { FlexContainer, theme } from "ui";

type ProductsListContainerProps = {
  itensQuantity: number;
};

export const ProductsListContainer = styled(
  FlexContainer
)<ProductsListContainerProps>`
  gap: ${theme.space.x5};
  flex-wrap: wrap;

  @media ${theme.device.desktopFirst.mobileXL} {
    gap: ${theme.space.x3};
    justify-content: ${({ itensQuantity }) =>
      itensQuantity > 1 ? "space-between" : "center"};
  }
`;

interface CardWrapperProps {
  cursor?: string;
}

export const AddProductCard = styled(FlexContainer)<CardWrapperProps>`
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 350px;
  background-color: ${theme.colors.neutral.white};
  border: thin solid ${theme.colors.neutral["100"]};
  border-radius: 2px;
  user-select: none;
  transition: all 0.2s linear;
  position: relative;
  -webkit-tap-highlight-color: transparent !important;
  max-width: 18rem;
  padding: ${theme.space["x3.5"]};

  @media ${theme.device.desktopFirst.mobileXL} {
    height: 325px;
    max-width: calc(50% - 10px);
  }

  cursor: ${({ cursor }) => cursor};
`;

export const AddProductCardWrapper = styled(FlexContainer)`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.secondary["265"]};
  transition: 300ms ease;
  &:hover {
    color: ${theme.colors.secondary["700"]};
    path {
      stroke: ${theme.colors.secondary["700"]};
    }
  }
`;

export const Wrapper = styled(FlexContainer)`
  gap: 1rem;
  flex-direction: row;
`;
