import { useCallback, useEffect, useState } from "react";
import { useForm, UseFormRegister } from "react-hook-form";
import { Input, Label } from "components";
import { Image } from "components/Image";
import {
  EnvironmentAddDto,
  EnvironmentDto,
  GiftsListProduct,
  IFormValues,
  UpdateGiftDto,
} from "typing";
import ProductsList from "../ProductsList";
import {
  EnvironmentAddModalStyled,
  EnvironmentAddProductsList,
  EnvironmentProductsListName,
  Environment,
  EnvironmentProductsListTitle,
  EnvironmentProductsListFooter,
  EnvironmentName,
  EnvironmentSelectStyled,
  EnvironmentSelectedProducts,
  SelectedProduct,
} from "./styles";
import { useWeddingGiftListContext } from "../../../context/state";
import { EnvironmentSelect } from "../EnvironmentSelect";

interface EnvironmentAddModalProps {
  setIsFormInvalid: (isInvalid: boolean) => void;
  setEnvironment?: (environmentAddDto: EnvironmentAddDto) => void;
  environmentsList: EnvironmentDto[] | null | undefined;
  productsList?: GiftsListProduct[] | null | undefined;
  isMyList: boolean;
}

const EnvironmentAddModal = ({
  setIsFormInvalid,
  setEnvironment,
  environmentsList,
  productsList,
  isMyList,
}: EnvironmentAddModalProps) => {
  const { isModalEdit, isModalTransfer, isModalDelete, environmentToRename } =
    useWeddingGiftListContext();
  const { register, watch, setValue } = useForm<EnvironmentAddDto>();
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [productsTransfer, setProductsTransfer] = useState<UpdateGiftDto[]>([]);
  const [selectedEnvironment, setSelectedEnvironment] = useState<string>("");
  const [countProductsSelected, setCountProductsSelected] =
    useState<string>("");

  const environmentName = watch("name");

  const newEnvironmentList = environmentsList?.filter((environment) => {
    return environment.id !== -2;
  });

  useEffect(() => {
    if (isModalEdit && environmentToRename) {
      setValue("name", environmentToRename?.name);
    }

    if (isModalDelete) {
      setIsFormInvalid(false);
    }
  }, [
    environmentToRename,
    isModalEdit,
    isModalDelete,
    setValue,
    productsList,
    setIsFormInvalid,
  ]);

  useEffect(() => {
    if (setEnvironment) {
      if (!isModalEdit) {
        setEnvironment({
          name: environmentName,
          productIds: selectedProducts,
        });
      } else {
        setEnvironment({
          id: environmentToRename?.id,
          name: environmentName,
        });
      }
    }

    if (environmentName && environmentName?.length <= 50) {
      setIsFormInvalid(false);
    } else {
      setIsFormInvalid(true);
    }
  }, [
    environmentName,
    selectedProducts,
    setSelectedProducts,
    setIsFormInvalid,
    setEnvironment,
    isModalEdit,
    environmentToRename,
  ]);

  useEffect(() => {
    const productsToTransfer: UpdateGiftDto[] = [];

    if (selectedEnvironment) {
      setIsFormInvalid(false);
      productsList?.forEach((product) => {
        productsToTransfer.push({
          productId: product.productId,
          quantity: null,
          environmentId: +selectedEnvironment,
        });
      });
    } else {
      setIsFormInvalid(true);
    }

    setProductsTransfer(productsToTransfer);
  }, [
    selectedEnvironment,
    setProductsTransfer,
    productsList,
    setIsFormInvalid,
  ]);

  useEffect(() => {
    if (setEnvironment) {
      setEnvironment({
        productsList: productsTransfer,
      });
    }
  }, [productsTransfer, setEnvironment]);

  // useEffect(() => {
  //   const removeGiftCardsAndReorder = (
  //     environment: EnvironmentDto,
  //     index: number,
  //     arr: EnvironmentDto[]
  //   ) => {
  //     if (environment.id === -2) {
  //       arr.splice(index, 1);
  //       arr.sort();
  //       return true;
  //     }

  //     return false;
  //   };
  //   environmentsList?.filter(removeGiftCardsAndReorder);
  // }, [environmentsList]);

  const handleProductSelected = useCallback(
    (product: GiftsListProduct) => {
      if (product.isSelected) {
        selectedProducts.push(product.productId);
      } else {
        const index = selectedProducts.indexOf(product.productId);
        if (selectedProducts.indexOf(product.productId) !== -1) {
          selectedProducts.splice(index, 1);
        }
      }

      setSelectedProducts(selectedProducts);
      if (setEnvironment) {
        setEnvironment({
          name: environmentName,
          productIds: selectedProducts,
        });
      }

      setCountProductsSelected(() => {
        return (
          `${selectedProducts.length} ` +
          `${selectedProducts.length <= 1 ? "Selecionado" : "Selecionados"}`
        );
      });
    },
    [selectedProducts, environmentName, setEnvironment, setSelectedProducts]
  );

  return (
    <EnvironmentAddModalStyled>
      {isModalTransfer ? (
        <>
          <EnvironmentSelectedProducts>
            {productsList?.map((product: GiftsListProduct) => (
              <SelectedProduct key={`${product?.productId}`}>
                <Image
                  src={product?.imageUrl}
                  alt={product?.productBrand}
                  width={170}
                  height={120}
                  objectFit="contain"
                />
              </SelectedProduct>
            ))}
          </EnvironmentSelectedProducts>
          <EnvironmentSelectStyled>
            <EnvironmentSelect
              data={environmentsList}
              disabled={false}
              onSelectOption={setSelectedEnvironment}
            />
          </EnvironmentSelectStyled>
        </>
      ) : (
        <EnvironmentName>
          <Label htmlFor="name" text="Nome do ambiente" />
          <Input
            hasError={environmentName && environmentName?.length > 50}
            textError="Nome do ambiente muito grande"
            minLength={5}
            maxLength={50}
            hasSuccessfully={!!environmentName?.length}
            name="name"
            id="name"
            placeholder="Ex: Sala de Estar"
            register={register as unknown as UseFormRegister<IFormValues>}
            type="text"
            onChange={({ target }) => setValue("name", target.value)}
            width="100%"
            required
          />
        </EnvironmentName>
      )}

      {!isModalEdit && !isModalTransfer && (
        <>
          <EnvironmentProductsListTitle>
            Transferir produtos (Opcional)
          </EnvironmentProductsListTitle>
          <EnvironmentAddProductsList>
            {newEnvironmentList?.map((environment: EnvironmentDto) => (
              <Environment key={`${environment?.id}`}>
                <EnvironmentProductsListName>
                  {environment?.name}
                </EnvironmentProductsListName>
                <ProductsList
                  products={environment?.products}
                  productsSelected={handleProductSelected}
                  compact
                  isMyList={isMyList}
                />
              </Environment>
            ))}
          </EnvironmentAddProductsList>
          <EnvironmentProductsListFooter>
            {countProductsSelected}
          </EnvironmentProductsListFooter>
        </>
      )}
    </EnvironmentAddModalStyled>
  );
};

export default EnvironmentAddModal;
