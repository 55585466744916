import styled from "styled-components";
import { theme } from "ui";

export interface GiftsListContentProps {
  padding?: string;
}

export const GiftsLisContent = styled.section<GiftsListContentProps>`
  display: grid;
  width: 100%;
  grid-template-columns: 25% 75%;
  padding: ${({ padding }) => padding ?? 0};

  @media ${theme.device.desktopFirst.laptop} {
    grid-template-columns: 22% 78%;
  }

  @media ${theme.device.desktopFirst.tablet} {
    grid-template-columns: 100%;
  }
`;

export const GiftsListMain = styled.section`
  width: 100%;

  @media ${theme.device.desktopFirst.mobileL} {
    padding: ${theme.space["x3.5"]} 0;
  }
`;

export const IconWrapper = styled.section`
  margin-top: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 10px;
`;

export const EnvironmentAddButton = styled.button`
  display: flex;
  font-size: ${theme.typography.fontSize.sm.x7};
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: ${theme.space["x3.5"]} 0;
  cursor: pointer;
  box-sizing: border-box;
  background: ${theme.colors.neutral.white};
  border: thin solid ${theme.colors.secondary["265"]};
  color: ${theme.colors.secondary["265"]};
  border-radius: 4px;
  font-weight: ${theme.typography.fontWeight["700"]};
  transition: background-color 0.2s ease-in-out;
  width: 100%;

  &:hover {
    background-color: ${theme.colors.secondary["25"]};
  }

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: 0.5rem 0;
  }
`;

export const MobileFilterButton = styled.button`
  display: flex;
  font-size: ${theme.typography.fontSize.sm.x5};
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 0 0 2rem 0;
  cursor: pointer;
  box-sizing: border-box;
  background: ${theme.colors.neutral.white};
  border: thin solid ${theme.colors.neutral["99"]};
  color: ${theme.colors.neutral["520"]};
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  width: 100%;

  @media ${theme.device.desktopFirst.mobileXL} {
    padding: 0.25rem 0;
  }
`;

export const SkeletonContainer = styled.div`
  display: flex;

  @media ${theme.device.desktopFirst.mobileXL} {
    justify-content: center;
  }
`;

export const TextAlert = styled.strong``;
