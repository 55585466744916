import styled from "styled-components";
import { FlexContainer, theme } from "ui";

interface CardWrapperProps {
  cursor?: string;
}

export const GiftVoucherCardStyled = styled(FlexContainer)<CardWrapperProps>`
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 350px;
  background-color: ${theme.colors.neutral.white};
  border: thin solid ${theme.colors.neutral["100"]};
  border-radius: 2px;
  user-select: none;
  transition: all 0.2s linear;
  position: relative;
  -webkit-tap-highlight-color: transparent !important;
  max-width: 18rem;
  padding: ${theme.space["x3.5"]};

  @media ${theme.device.desktopFirst.mobileL} {
    height: 325px;
    max-width: calc(50% - 10px);
  }
  cursor: ${({ cursor }) => cursor};
`;

export const CardWrapper = styled(FlexContainer)<CardWrapperProps>`
  flex-direction: column;
  width: 18rem;
  border: thin solid ${theme.colors.neutral["150"]};
  min-height: 28rem;
  padding: 1.1rem;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  margin: 0 0.5rem 1rem;
  cursor: ${({ cursor }) => cursor};
`;

export const AddProductCard = styled(FlexContainer)<CardWrapperProps>`
  flex-direction: column;
  align-items: left;
  justify-content: center;
  height: 350px;
  user-select: none;
  transition: all 0.2s linear;
  position: relative;
  -webkit-tap-highlight-color: transparent !important;
  max-width: 18rem;

  @media ${theme.device.desktopFirst.mobileL} {
    height: 325px;
    max-width: calc(50% - 10px);
  }
  cursor: ${({ cursor }) => cursor};
`;

export const AddProductCardWrapper = styled(FlexContainer)`
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: ${theme.colors.secondary["265"]};
  transition: 300ms ease;
  &:hover {
    color: ${theme.colors.secondary["700"]};
    path {
      stroke: ${theme.colors.secondary["700"]};
    }
  }
`;

export const Wrapper = styled(FlexContainer)`
  justify-content: center;
  flex-wrap: wrap;
  max-height: 30rem;
  gap: 1rem;
  flex-direction: row;
  overflow: hidden;
  @media ${theme.device.desktopFirst.desktopS} {
    overflow-y: scroll;
  }
`;
