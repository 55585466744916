import styled from "styled-components";
import { theme, EditPencilIcon } from "ui";
import { Camera } from "ui/Icons";

interface SectionProps {
  isHeader?: boolean;
  isReducedScreen?: boolean;
  isDisabled?: boolean;
  isHover?: boolean;
}

const MAX_WIDTH = 755;

export const Container = styled.div``;

export const AlterHeaderText = styled.p`
  opacity: 0;
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight[500]};
  color: ${theme.colors.neutral.white};
`;

export const AlterHeaderContainer = styled.div`
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 1;
  opacity: 1;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.neutral["80"]};
  padding: 0;
  border-radius: 50%;

  :hover {
    background-color: ${theme.colors.primary["200"]};
  }

  svg {
    path {
      -webkit-transition-property: none;
      -moz-transition-property: none;
      -o-transition-property: none;
      transition-property: none;
    }
  }
`;

type HeaderProps = {
  isDisabled: boolean;
};

export const Header = styled.div<HeaderProps>`
  position: relative;
  width: 100%;
  height: 235px;

  @media (max-width: ${MAX_WIDTH}px) {
    height: 120px;
    ${AlterHeaderContainer} {
      opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
      ${AlterHeaderText} {
        color: ${theme.colors.neutral.white};
        opacity: ${({ isDisabled }) => (isDisabled ? 0.5 : 1)};
      }
    }
  }
`;

export const Photo = styled.div<SectionProps>`
  background-color: ${theme.colors.neutral[30]};
  position: absolute;
  height: 215px;
  width: 215px;
  border-radius: 100%;
  border: 2px solid;
  border-color: ${theme.colors.neutral.white};
  top: 32px;
  left: 0;
  right: 0;
  margin: auto;

  img {
    display: block;
    transform: scale(1);
  }

  @media (max-width: ${MAX_WIDTH}px) {
    height: 120px;
    width: 120px;
  }
`;

export const AlterImageText = styled.p`
  opacity: 0;
  font-size: ${theme.typography.fontSize.sm.x5};
  font-weight: ${theme.typography.fontWeight[500]};
  color: ${theme.colors.neutral.white};

  ::before {
    content: "Alterar imagem do \\a perfil da lista.";
    white-space: pre;
  }

  @media (max-width: ${MAX_WIDTH}px) {
    opacity: 1;
    padding-bottom: 5px;
    ::before {
      content: "Alterar";
    }
  }
`;

export const AlterImageContainer = styled.div<SectionProps>`
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 6px;
  right: 6px;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  text-align: center;
  background-color: ${theme.colors.neutral["80"]};
  opacity: 1;
  border-radius: 50%;
  }
  :hover {
    background-color: ${theme.colors.primary["200"]};
  }
  
  svg {
    path {
      -webkit-transition-property: none;
      -moz-transition-property: none;
      -o-transition-property: none;
      transition-property: none;
    }
  }

  @media (max-width: ${MAX_WIDTH}px) {
    opacity: 1;
    height: 50%;
    bottom: 0;
  }
`;

export const FileButton = styled.input.attrs({
  type: "file",
  accept: "image/png, image/jpeg",
})`
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  &::-webkit-file-upload-button {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const CameraIcon = styled(Camera).attrs(
  ({ isHeader, isReducedScreen }: SectionProps) => ({
    width: isHeader ? "30" : isReducedScreen ? "25" : "40",
    height: isHeader ? "30" : isReducedScreen ? "25" : "40",
    color: theme.colors.neutral.white,
  })
)<SectionProps>``;

export const EditIcon = styled(EditPencilIcon).attrs(
  ({ isHover }: SectionProps) => ({
    color: isHover ? theme.colors.neutral.white : theme.colors.neutral["999"],
  })
)<SectionProps>``;
