import { ReactNode } from "react";
import {
  BaseModal,
  BaseModalBody,
  BaseModalHeader,
  BaseModalHeaderClose,
  BaseModalHeaderTitle,
  CloseIcon,
} from "./style";

export interface ProductDetailModalProps {
  children?: ReactNode;
  height?: string;
  isOpen: boolean;
  footerGap?: string;
  visible?: string;
  onCloseModal: () => void;
  contentLabel: string;
}

const ProductDetailModal = ({
  children,
  height,
  onCloseModal,
  // modalButtonPadding,
  visible = "visible",
  ...props
}: ProductDetailModalProps) => {
  return (
    <BaseModal
      {...props}
      styles={{
        content: {
          padding: "2rem",
          height: height ?? "fit-content",
          visibility: visible,
        },
        overlay: {
          visibility: visible,
        },
      }}
      onCloseModal={onCloseModal}
    >
      <BaseModalHeader data-testid="base-modal-header">
        <BaseModalHeaderTitle data-testid="base-modal-header-title" />
        <BaseModalHeaderClose
          data-testid="base-modal-closer"
          onClick={onCloseModal}
        >
          <CloseIcon />
        </BaseModalHeaderClose>
      </BaseModalHeader>
      <BaseModalBody data-testid="base-modal-body">{children}</BaseModalBody>
    </BaseModal>
  );
};

export { ProductDetailModal };
